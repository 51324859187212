import type { TFunction } from '@loveholidays/phrasebook';

import { PageType } from '@AuroraTypes';
import {
  FavouritesList,
  Notification,
  NotificationType,
  HotelInfo,
} from '@Components/Favourites/interfaces';
import { createStore } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';

export interface FavouritesStore {
  fetched: boolean;
  favouritesLists: FavouritesList[];
  notification: Notification | null;
  loginModal: { show: boolean; masterId?: number };
  setFetched: (fetched: boolean) => void;
  setShowLoginModal: (showLoginModal: boolean, masterId?: number) => void;
  setFavouritesLists: (favouritesLists: FavouritesList[]) => void;
  createFavouritesList: (list: Omit<FavouritesList, 'updatedAt'>) => void;
  createFavouritesLists: (list: Omit<FavouritesList, 'updatedAt'>[]) => void;
  deleteFavouritesList: (listId: string) => void;
  updateFavouritesListTitle: (listId: string, name: string) => void;
  updateFavouritesListSearchQuery: (listId: string, searchQuery: string) => void;
  addFavourite: (
    favouritesList: Omit<FavouritesList, 'updatedAt'>,
    masterId: number,
    hotelInfo: HotelInfo,
  ) => void;
  removeFavourite: (masterId: number) => void;
  addHotelToFavouritesList: (masterId: number, listId: string) => void;
  removeHotelFromFavouritesList: (masterId: number, listId: string, pageType?: PageType) => void;
  getNumberOfSavedHolidays: () => number;
  notificationClose: () => void;
  setInitialValues: (overrides?: Partial<FavouritesStore>) => void;
}

export const createFavouritesStore = (t: TFunction, initialValues?: Partial<FavouritesStore>) => {
  const notification: Notification | null = null;
  const notificationTime = 5000;

  return createStore<FavouritesStore>((set, get) => {
    const saveNotification = (data: Notification | null) => {
      set({
        notification: data,
      });
    };

    const closeNotification = () => {
      const { notification } = get();
      set({
        notification: notification
          ? {
              ...notification,
              isOpened: false,
              previousLists: undefined,
              previousHotelRemoved: undefined,
              hotelInfo: undefined,
              listInfo: undefined,
            }
          : null,
      });
    };

    const updateNotificationTimer = () => {
      const { notification } = get();

      clearTimeout(notification?.timerId);

      const timerId: any = setTimeout(() => {
        closeNotification();
      }, notificationTime);

      return timerId;
    };

    const addFavouritesList = (newFavouritesList: Omit<FavouritesList, 'updatedAt'>) => {
      const { favouritesLists } = get();

      const updatedList: FavouritesList[] = [
        {
          ...newFavouritesList,
          updatedAt: 1, // Remove this once we no longer use localStorage favourites
        },
        ...favouritesLists,
      ];

      set({ favouritesLists: updatedList });
    };

    const addToFavouritesList = (masterId: number, listId: string) => {
      const { favouritesLists } = get();

      const updatedList = favouritesLists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            items: [masterId, ...list.items],
          };
        }

        return list;
      });

      set({ favouritesLists: updatedList });
    };

    return {
      fetched: false,
      favouritesLists: [],
      notification,
      loginModal: { show: false },

      setFetched: (fetched) => {
        set({ fetched });
      },

      setShowLoginModal: (showLoginModal, masterId) => {
        set({ loginModal: { show: showLoginModal, masterId } });
      },

      setFavouritesLists: (favouritesLists) => {
        set({ favouritesLists });
      },

      createFavouritesList: (newFavouritesList) => {
        addFavouritesList(newFavouritesList);
      },

      createFavouritesLists: (newFavouritesLists) => {
        const { favouritesLists } = get();

        const newLists = newFavouritesLists.map((list) => ({
          ...list,
          updatedAt: 1, // Remove this once we no longer use localStorage favourites
        }));

        const updatedList = [...newLists, ...favouritesLists];

        set({ favouritesLists: updatedList });
      },

      deleteFavouritesList: (listId) => {
        const { favouritesLists } = get();

        const updatedList = favouritesLists.filter((list) => list.id !== listId);

        set({ favouritesLists: updatedList });
      },

      updateFavouritesListTitle: (listId, name) => {
        const { favouritesLists } = get();

        const updatedLists = favouritesLists.map((list) => {
          if (list.id === listId) {
            return {
              ...list,
              title: name,
            };
          }

          return list;
        });

        set({ favouritesLists: updatedLists });
      },

      updateFavouritesListSearchQuery: (listId, searchQuery) => {
        const { favouritesLists } = get();

        const updatedLists = favouritesLists.map((list) => {
          if (list.id === listId) {
            return {
              ...list,
              search: searchQuery,
            };
          }

          return list;
        });

        set({ favouritesLists: updatedLists });
      },

      addFavourite: (favouritesList, masterId, hotelInfo) => {
        const { favouritesLists } = get();
        const timerId = updateNotificationTimer();

        if (favouritesLists.length === 0) {
          // User doesn't have any lists so default was created
          addFavouritesList(favouritesList);
        } else {
          const updatedList = favouritesLists.map((list) => {
            if (list.id === favouritesList.id) {
              return {
                ...list,
                items: favouritesList.items,
              };
            }

            return list;
          });

          set({ favouritesLists: updatedList });
        }

        // Create add notification
        saveNotification({
          timerId,
          isOpened: true,
          type: NotificationType.Added,
          masterId,
          listInfo: {
            id: favouritesList.id,
            title: favouritesList.title,
          },
          hotelInfo,
        });
      },

      removeFavourite: (masterId: number) => {
        const { favouritesLists } = get();
        const timerId = updateNotificationTimer();

        let countIds = 0;
        let listInfo: Pick<FavouritesList, 'id' | 'title'> | undefined;

        const updatedLists = favouritesLists.map((list: FavouritesList) => ({
          ...list,
          items: list.items.filter((item) => {
            if (item === masterId) {
              countIds += 1;
              listInfo = {
                id: list.id,
                title: list.title,
              };
            }

            return item !== masterId;
          }),
        }));

        set({ favouritesLists: updatedLists });

        // Create remove notification
        saveNotification({
          isOpened: true,
          timerId,
          type: NotificationType.Removed,
          masterId,
          listInfo: countIds <= 1 ? listInfo : undefined,
          previousLists: favouritesLists,
        });
      },

      addHotelToFavouritesList: (masterId: number, listId: string) => {
        addToFavouritesList(masterId, listId);
      },

      removeHotelFromFavouritesList: (masterId: number, listId: string, pageType?: PageType) => {
        const { favouritesLists } = get();

        let title = '';
        const updatedList = favouritesLists.map((list) => {
          if (list.id === listId) {
            title = list.title;

            return {
              ...list,
              items: list.items.filter((item) => item !== masterId),
            };
          }

          return list;
        });

        set({ favouritesLists: updatedList });

        if (pageType === 'favouriteslistdetails') {
          const timerId = updateNotificationTimer();

          // Create remove notification with undo
          saveNotification({
            isOpened: true,
            timerId,
            type: NotificationType.Removed,
            masterId,
            listInfo: { id: listId, title },
            // Save previousHotelRemoved for undo action
            previousHotelRemoved: { listId, masterId, title },
          });
        }
      },

      getNumberOfSavedHolidays: () => {
        const { favouritesLists } = get();

        return favouritesLists.reduce((acc, list) => acc + list.items.length, 0);
      },

      notificationClose: () => {
        closeNotification();
      },

      setInitialValues: (overrides) => {
        const extended = createFavouritesStore(t, overrides).getState();

        set(removeFunctionMembers(extended) as FavouritesStore);
      },

      ...initialValues,
    };
  }, 'FavouritesStore');
};
