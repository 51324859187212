import { PageContent } from '@AuroraTypes';
import { createStore, Store } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';

export interface GenericPageStore {
  status: 'loading' | 'refreshing' | 'ok' | 'content-not-found' | 'client-error';
  errorMessage: string;
  pageContent: PageContent;
  setInitialValues: (overrides?: Partial<GenericPageStore>) => void;
}

export const DEFAULT_PAGE_CONTENT: PageContent = {
  pageType: 'other',
  breadcrumbs: [],
  header: { megaMenu: [] },
  footer: { links: [] },
  notices: [],
  showBreadcrumbsOnMobile: false,
  supplementaryLayout: undefined,
  meta: undefined,
  layout: [],
  pageLayout: {
    sections: [],
    components: [],
  },
} as any;

export const createGenericPageStore = (
  initialValues?: Partial<GenericPageStore>,
): Store<GenericPageStore> =>
  createStore<GenericPageStore>(
    (set) => ({
      status: 'ok',
      errorType: 'none',
      errorMessage: '',
      pageContent: DEFAULT_PAGE_CONTENT,
      ...initialValues,
      setInitialValues: (overrides?: Partial<GenericPageStore>) => {
        const extended = createGenericPageStore(overrides).getState();
        set(removeFunctionMembers(extended) as GenericPageStore);
      },
    }),
    'GenericPageStore',
  );
