import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { preLoadedLoadable as loadable } from '@Core/preLoadedLoadable';

export const preloadHandOffPage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useFeatureFlag('UseOldCheckout')) {
    return;
  }

  loadable(() => import('@Components/LayoutComponents/HandoffPage'));
};
