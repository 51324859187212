import React, { memo, useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { LoadablePandaLoadingLayout } from './panda/LoadablePandaLoadingLayout';
import { PageType } from '@AuroraTypes';
import { setPageTypeClient, useAppContext } from '@Contexts/contexts';
import { usePageLayoutContext } from '@Contexts/PageLayoutContext';
import { useRouteContext } from '@Contexts/RouteContext';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { scrollToTop } from '@Core/scroll-helpers/scrollToTop';
import { useIdentifyTracking } from '@Core/tracking/hooks/useIdentifyTracking';
import { Route as RouteType } from '@Server/routes/Route';
import { useFeatureFlagStore } from '@Stores/FeatureFlag/FeatureFlagStore';
import { usePageViewTracking } from '@Tracking';

interface PageRouteProps {
  route: RouteType;
  originalPageType: PageType;
  path: string;
}

const PageRoute: React.FC<PageRouteProps> = memo(({ route, originalPageType, path }) => {
  const trackIdentify = useIdentifyTracking();
  const trackPageView = usePageViewTracking();
  useEffect(() => {
    trackIdentify();
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const status = useFeatureFlagStore((state) => state.status);
  const isGenericSRPPanda = useFeatureFlag('GenericSRPPanda');
  const { setHeaderConfig, setFooterConfig } = usePageLayoutContext();

  if (status === 'fetching' && originalPageType !== route.pageType) {
    return <LoadablePandaLoadingLayout />;
  }

  route.onRender?.();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setHeaderConfig(route.header);
    setFooterConfig(route.footer);
    if (route.onMount) {
      route.onMount();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // Scroll to top when navigating to a non-generic page, or the non-generic SRP & Panda pages as
    // they are mid-migration so isGeneric isn't set accurately based on FFs
    if (
      !route.isGeneric ||
      (isGenericSRPPanda === false && ['srp', 'panda'].includes(route.pageType))
    ) {
      scrollToTop();
    }
  }, [isGenericSRPPanda, route.isGeneric, route.pageType]);

  const Component = route.component;

  return <Component {...route} />;
});

export const RouterSwitch: React.FC = memo(() => {
  const {
    routes,
    site: { pathPrefix },
  } = useAppContext();
  const { pageType } = useRouteContext();
  const [originalPageType] = useState(pageType);

  return useMemo(
    () => (
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={({ location }) => {
              setPageTypeClient(`${pathPrefix}${location.pathname}`, route.pageType);

              return (
                <PageRoute
                  route={route}
                  path={location.pathname}
                  originalPageType={originalPageType}
                />
              );
            }}
          />
        ))}
      </Switch>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [originalPageType, routes, pathPrefix],
  );
});
