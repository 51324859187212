import { createContext, useContext, useMemo } from 'react';

import { PageType } from '@AuroraTypes';
import { sendEvent } from '@Core/tracking/sendEvent';
import {
  ErrorEventType,
  ErrorWebEventTrackingObject,
  TrackingEvent,
  WebEventCategory,
} from '@Core/tracking/types';
import { AppContext as AppContextType } from '@Server/handlers/handle-contexts/types';
import { loadAllRoutes } from '@Server/routes/main/routes';

/** Raise alerts if we wait longer than this time for a page type */
const MAX_PAGE_TYPE_WAIT_TIME = 7000;

export const AppContext = createContext<AppContextType>({} as AppContextType);
export const useAppContext = () => {
  const state = useContext(AppContext);

  const routes = useMemo(() => loadAllRoutes(state.site.pathTemplates), [state.site.pathTemplates]);

  return {
    ...state,
    routes,
  };
};

/**
 * Get the site Code
 *
 * Usually you should use `useAppContext` and get the site code from there. Only use this if you're
 * outside of the react component tree.
 *
 * Only works client side.
 */
export const getClientSiteCode = () =>
  typeof window === 'undefined' ? undefined : window.__CONTEXTS__?.App?.site.siteCode;

const pageTypeMappings: Record<string, PageType> = {};
const pageTypeResolveCallbacks: Record<string, Array<(value: PageType) => void>> = {};

export function setPageTypeClient(
  /** Path of the page. This should include the site path prefix. Eg `/de/urlaub/` */
  path: string,
  pageType: PageType,
) {
  if (
    typeof window === 'undefined' ||
    pageType === ('generic' as PageType) ||
    pageTypeMappings[path] !== undefined
  ) {
    return;
  }
  pageTypeMappings[path] = pageType;

  for (const callback of pageTypeResolveCallbacks[path] ?? []) {
    callback(pageType);
  }

  delete pageTypeResolveCallbacks[path];
}

/**
 * Get the page type of the current path synchronously.
 *
 * If it isn't immediately available then it will return `undefined`.
 */
export function getPageTypeClientSync(
  /** Path of the page. This should include the site path prefix. Eg `/de/urlaub/`.
   * This must be usable from places that only have access to `window.location.pathname`.
   */
  path: string,
): PageType | undefined {
  return pageTypeMappings[path];
}

export async function getPageTypeClient(
  /** Path of the page. This should include the site path prefix. Eg `/de/urlaub/`.
   * This must be usable from places that only have access to `window.location.pathname`.
   */
  path: string,
): Promise<PageType> {
  if (typeof window === 'undefined') {
    return Promise.reject('Do not call `getPageTypeClient` from the server');
  }

  const fromMapping = getPageTypeClientSync(path);
  if (fromMapping) {
    return fromMapping;
  }

  if (pageTypeResolveCallbacks[path] === undefined) {
    pageTypeResolveCallbacks[path] = [];
  }

  const promise = new Promise<PageType>((resolveCallback) => {
    pageTypeResolveCallbacks[path].push(resolveCallback);
  });

  // Alert if we're too slow
  let sentTimeoutAlert = false;
  const timeoutReporter = setTimeout(() => {
    sendEvent<ErrorWebEventTrackingObject>({
      event: TrackingEvent.webEvent,
      category: WebEventCategory.error,
      errorType: 'failedToResolvePageType' as ErrorEventType,
      action: 'get-page-type',
      label: 'Failed to resolve page type in a reasonable time',
      timeout: MAX_PAGE_TYPE_WAIT_TIME,
      path,
    });

    sentTimeoutAlert = true;
  }, MAX_PAGE_TYPE_WAIT_TIME);
  promise.then(() => clearTimeout(timeoutReporter));

  // Follow up alert to differentiate slow from broken
  const startTime = performance.now();
  promise.then((pageType) => {
    if (sentTimeoutAlert) {
      sendEvent<ErrorWebEventTrackingObject>({
        event: TrackingEvent.webEvent,
        category: WebEventCategory.error,
        errorType: 'pageTypeResolvedSlowly' as ErrorEventType,
        action: 'get-page-type',
        label: 'Resolved page type in an unreasonable time',
        duration: performance.now() - startTime,
        pageType,
        path,
      });
    }
  });

  return promise;
}

if (typeof window !== 'undefined') {
  window.getPageType = getPageTypeClient;
}
