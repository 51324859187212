import loadable from '@loadable/component';
import React, { memo } from 'react';

import { useAppContext } from '@Client/context/contexts';

export const Header: React.FC = memo(() => {
  const app = useAppContext();

  const headerName = app.site.brand === 'holidaypirates' ? 'holidaypirates' : 'standard';

  const Header = loadable(() => import(`./variants/${headerName}/Header`));

  return <Header />;
});
