import { useInView } from '@loveholidays/design-system';

import { sendEvent } from '../sendEvent';
import { TrackingObject } from '../types';

export const useInViewTracking = (eventData: TrackingObject, disabled?: boolean) => {
  const ref = useInView(
    (isInView) => {
      if (isInView) {
        sendEvent(eventData);
      }
    },
    {
      triggerOnlyOnce: true,
      threshold: 0.45,
      disabled,
    },
  );

  return ref;
};
