import { useLocation } from 'react-router-dom';

import { URLQueryParams } from '@Core/url/URLQueryParams';

export function parseParams(paramsString: string): Record<string, string> {
  return new URLQueryParams(paramsString).toObject();
}

export function getQueryParams(searchQuery: string): Record<string, string> {
  return parseParams(searchQuery.replace(/^\??/, ''));
}

export function useQueryParams() {
  const { search } = useLocation();

  return getQueryParams(search);
}

export function getHashParams(hash: string): Record<string, string> {
  return parseParams(hash.replace(/^#?/, ''));
}

export const splitUrlParts = (url: string): Pick<URL, 'pathname' | 'search' | 'hash'> => {
  const { pathname, search, hash } = new URL(url, 'http://example.com');

  return {
    pathname,
    search: search[0] === '?' ? search.substr(1) : search,
    hash: hash[0] === '#' ? hash.substr(1) : hash,
  };
};

export const mergeSearchParams = (
  currentSearch: string,
  nextSearch: string,
  paramsToKeep: string[] = [],
) => {
  if (!paramsToKeep.length) {
    return nextSearch;
  }

  const current = new URLQueryParams(currentSearch);
  const merged = new URLQueryParams(nextSearch);

  paramsToKeep.forEach((name) => {
    if (current.has(name)) {
      merged.set(name, current.get(name)!);
    }
  });

  return merged.toString();
};

export const toSearchParams = (obj: Record<string, unknown>) =>
  Object.entries(obj).reduce(
    (result, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        // eslint-disable-next-line no-param-reassign
        result[key] = `${value}`;
      }

      return result;
    },
    {} as { [key: string]: string },
  );
