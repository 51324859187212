import React, { ReactElement } from 'react';

import {
  DOMInteractionEvent,
  useInteractionTracking,
} from '@Core/tracking/hooks/useInteractionTracking';

interface ClickTrackingEventType {
  /**
   * The event action
   */
  action: string;
  /**
   * Optional data-track-label attribute, or the dataLayer label
   */
  label?: string;
  /**
   * Optional boolean to force the data-track attributes onto a given element
   */
  useDataTracking?: boolean;
  /**
   * Optional boolean to identify when tracking is disabled (ignored for data tracking)
   */
  disabled?: boolean;
  children: ReactElement;
}

export const WithClickTracking: React.FC<ClickTrackingEventType> = ({
  action,
  label,
  useDataTracking = true,
  disabled = false,
  children,
}) => {
  const child = React.Children.only(children);
  const isClosedNode = React.Children.count(child.props.children) === 0; // eg: <img src="..." />
  const isTextNode =
    React.Children.count(child.props.children) === 1 && typeof children.props.children === 'string';
  // TODO: Add in check for link so that we can we ref tracking
  const isLeafNode = isClosedNode || isTextNode;
  const ref = useInteractionTracking({
    event: DOMInteractionEvent.CLICK,
    action,
    label,
    filter: () => !disabled,
  });

  return React.cloneElement(
    child,
    !isLeafNode || !useDataTracking
      ? { ref }
      : {
          'data-track': action,
          'data-track-label': label,
        },
  );
};
