import { BaseSearchSelectionStore } from './types';
import { DynamicPackageFiltersInput } from '@AuroraTypes';
import { DEFAULT_FLEXIBILITY } from '@Constants/Flexibility';

export const defaultFilterValues: DynamicPackageFiltersInput = {
  boardBasis: [],
  holidayTypes: [],
  hotelFacilities: [],
  kids: [],
  promotionTags: [],
  maxPerPersonPrice: undefined,
  minTripAdvisorRating: undefined,
  minHolidayCheckRating: undefined,
  pools: [],
  propertyTypes: [],
  starRating: [],
  regions: [],
  resorts: [],
  cancellationPolicies: [],
  includedCheckedBags: [],
  maxFlightStops: [],
  outboundTimeBuckets: [],
  inboundTimeBuckets: [],
  location: undefined,
};

export const defaultSortValue = 'POPULAR';

export const defaultSearchSelection: BaseSearchSelectionStore = {
  departureAirports: [],
  destinationIds: [],
  flexibility: DEFAULT_FLEXIBILITY,
  nights: 7,
  rooms: [
    {
      adults: 2,
      childAges: [],
    },
  ],
  sort: defaultSortValue,
  filters: {
    ...defaultFilterValues,
  },
};
