import { Client, OperationContext, OperationResult } from 'urql';

import { Query } from '@AuroraTypes';

/**
 * Reads the query result from cache as sync or fetches the query as async if the result was
 * not found in the cache.
 * We need this beacuse on SSR prepass we are fetching data from Aurora as an async task,
 * but in React render we can't do async fetching as React doesn't support Suspense in server render.
 * As the data was already cached during prepass, we can just read it as a sync task in React render.
 */
export function readOrFetchQuery<T = OperationResult<Query>>(
  client: Client,
  query: string,
  variables?: Record<string, unknown>,
  transformer: (op: OperationResult<Query>) => any = (op) => op,
  context?: Partial<OperationContext>,
): T | Promise<T> {
  try {
    const cachedResult = client.readQuery<Query>(query, variables, context)!;

    return transformer(cachedResult);
  } catch (e) {
    return client.query<Query>(query, variables, context).toPromise().then(transformer);
  }
}
