import { sendEvent } from '../sendEvent';
import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { TrackingEvent, IdentifyTracking } from '@Core/tracking/types';

export const useIdentifyTracking = () => {
  const { lvhCustomerId } = useUserPreferences();

  return (userId?: string) => {
    if (userId) {
      sendEvent<IdentifyTracking>({
        event: TrackingEvent.identify,
        userId,
      });
    } else {
      const customerId = lvhCustomerId.get();

      if (customerId) {
        sendEvent<IdentifyTracking>({
          event: TrackingEvent.identify,
          userId: customerId,
        });
      }
    }
  };
};
