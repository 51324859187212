import {
  priceQuoteUnitCookie,
  lvhIdCookie,
  lvhCidCookie,
  legacyBridgeState,
  savedSearchSelectionCookie,
  lvhChannel,
  cookiePreferences,
  criteoId,
  selectedAgentSiteCookie,
  agentLoginCookie,
  checkoutSession,
  partnerizeCookie,
  acceptedSitesCookie,
  consumableNotificationCookie,
} from '@loveholidays/skyline-cookies';

import {
  UserPreferencesConfiguration,
  CookieUserPreferencesConfiguration,
  LocalStorageUserPreferencesConfiguration,
  UserPreferencesAccessor,
  SessionStorageUserPreferencesConfiguration,
} from './interfaces';
import { SiteCode } from '@AuroraTypes';
import { StoredForm } from '@Components/Checkout/PassengerForm/types';
import { FavouritesList } from '@Components/Favourites/interfaces';
import { PriceQuoteUnit } from '@Constants/PriceQuoteUnit';
import { useAppContext } from '@Contexts/contexts';
import { ReturnToOfferItem, ReturnToOfferItemSunrise } from '@Pages/home/ReturnToOffer/types';
import { RecentlyViewedHotel } from '@Pages/panda/RecentlyViewedHotelsUpdater/types';

type NavigationStackItem = {
  path: string;
  search: string;
  searchTerm: string;
  pageType?: string;
};

export interface UserPreferencesAccessors {
  priceQuoteUnit: UserPreferencesAccessor<PriceQuoteUnit>;
  lvhId: UserPreferencesAccessor<string>;
  lvhCustomerId: UserPreferencesAccessor<string>;
  criteoId: UserPreferencesAccessor<string>;
  lvhChannel: UserPreferencesAccessor<string>;
  checkoutSession: UserPreferencesAccessor<string>;
  cookiePreferences: UserPreferencesAccessor<Record<SiteCode, string[]>>;
  recentlyViewedHotels: UserPreferencesAccessor<RecentlyViewedHotel[]>;
  legacyBridgeState: UserPreferencesAccessor<string>;
  returnToOffer: UserPreferencesAccessor<ReturnToOfferItem | ReturnToOfferItemSunrise | null>;
  savedSearchSelection: UserPreferencesAccessor<string>;
  Favourites: UserPreferencesAccessor<FavouritesList[]>;
  selectedAgentSite: UserPreferencesAccessor<string>;
  agentLoggedIn: UserPreferencesAccessor<boolean>;
  partnerizeRefMap: UserPreferencesAccessor<string | null>;
  acceptedSites: UserPreferencesAccessor<string[]>;
  feedbackFormShownAt: UserPreferencesAccessor<string | null>;
  lvhNotification: UserPreferencesAccessor<string>;
  passengerForms: UserPreferencesAccessor<StoredForm[]>;
  lvhBookingRefs: UserPreferencesAccessor<string[]>;
  lvhInvoicePayment: UserPreferencesAccessor<Record<string, string>>;
  lvhPrimerPaymentMethod: UserPreferencesAccessor<string>;
  lvhPrimerPaymentId: UserPreferencesAccessor<string>;
  navigationStack: UserPreferencesAccessor<NavigationStackItem[]>;
}

export const userPreferencesConfigurations: Record<string, UserPreferencesConfiguration<any>> = {
  priceQuoteUnit: {
    cookie: priceQuoteUnitCookie,
    readTransform: (value) => (value === 'true' ? 'TOTAL' : 'PER_PERSON'),
    writeTransform: (value) => String(value === 'TOTAL'),
    defaultValue: () => {
      const {
        site: { defaultPricingMethod },
        // eslint-disable-next-line react-hooks/rules-of-hooks
      } = useAppContext();

      return defaultPricingMethod;
    },
  } as CookieUserPreferencesConfiguration<PriceQuoteUnit>,

  lvhId: {
    cookie: lvhIdCookie,
  } as CookieUserPreferencesConfiguration<string>,

  lvhCustomerId: {
    cookie: lvhCidCookie,
  } as CookieUserPreferencesConfiguration<string>,

  lvhChannel: {
    cookie: lvhChannel,
  } as CookieUserPreferencesConfiguration<string>,

  cookiePreferences: {
    cookie: cookiePreferences,
  } as CookieUserPreferencesConfiguration<string>,

  criteoId: {
    cookie: criteoId,
  } as CookieUserPreferencesConfiguration<string>,

  checkoutSession: {
    cookie: checkoutSession,
  } as CookieUserPreferencesConfiguration<string>,

  recentlyViewedHotels: {
    isMultiSite: true,
    localStorageKey: 'recentHotels',
    readTransform: (value) => {
      if (typeof value === 'undefined' || !value) {
        return [];
      }

      if (typeof value === 'string') {
        return JSON.parse(value);
      }

      return value;
    },
    defaultValue: () => [],
  } as LocalStorageUserPreferencesConfiguration<RecentlyViewedHotel[]>,

  legacyBridgeState: {
    cookie: legacyBridgeState,
  } as CookieUserPreferencesConfiguration<string>,

  returnToOffer: {
    isMultiSite: true,
    localStorageKey: 'lvh_return_to_offer',
    readTransform: (value) => {
      if (typeof value === 'undefined' || !value) {
        return null;
      }

      if (typeof value === 'string') {
        return JSON.parse(value);
      }

      return value;
    },
    defaultValue: () => null,
  } as LocalStorageUserPreferencesConfiguration<ReturnToOfferItem | null>,

  savedSearchSelection: {
    isMultiSite: true,
    cookie: savedSearchSelectionCookie,
  } as CookieUserPreferencesConfiguration<string>,

  selectedAgentSite: {
    cookie: selectedAgentSiteCookie,
  } as CookieUserPreferencesConfiguration<string>,

  agentLoggedIn: {
    cookie: agentLoginCookie,
    readTransform: (value) => value === 'yes',
    defaultValue: () => false,
  } as CookieUserPreferencesConfiguration<boolean>,

  partnerizeRefMap: {
    isMultiSite: true,
    cookie: partnerizeCookie,
    readTransform: (value) => value ?? null,
    writeTransform: (value) => JSON.stringify(value),
  } as CookieUserPreferencesConfiguration<string | null>,

  // accepted_sites cookie is a comma-delimited list of sites
  acceptedSites: {
    cookie: acceptedSitesCookie,
    readTransform: (value) => (value ?? '').split(',').filter(Boolean),
    writeTransform: (value) => (value ?? []).join(','),
    defaultValue: () => [],
  } as CookieUserPreferencesConfiguration<string[]>,

  feedbackFormShownAt: {
    isMultiSite: true,
    localStorageKey: 'lvh_feedback_form_shown_at',
    defaultValue: () => null,
  } as LocalStorageUserPreferencesConfiguration<string | null>,

  lvhNotification: {
    cookie: {
      name: consumableNotificationCookie.name,
    },
  } as CookieUserPreferencesConfiguration<string>,

  passengerForms: {
    localStorageKey: 'passengersForms',
    readTransform: (value) => {
      try {
        return JSON.parse(value);
      } catch {
        return [];
      }
    },
    writeTransform: (value) => JSON.stringify(value),
    defaultValue: () => [],
  } as LocalStorageUserPreferencesConfiguration<StoredForm[]>,

  lvhBookingRefs: {
    localStorageKey: 'lvh_booking_refs',
    readTransform: (value) => {
      try {
        return JSON.parse(value);
      } catch {
        return [];
      }
    },
    writeTransform: (value) => JSON.stringify(value),
    defaultValue: () => [],
  } as LocalStorageUserPreferencesConfiguration<string[]>,

  lvhInvoicePayment: {
    sessionStorageKey: 'lvh_invoice_payment',
    readTransform: (value) => {
      try {
        return JSON.parse(value);
      } catch {
        return;
      }
    },
    writeTransform: (value) => JSON.stringify(value),
  } as SessionStorageUserPreferencesConfiguration<string | null>,

  lvhPrimerPaymentMethod: {
    sessionStorageKey: 'lvh_primer_payment_method',
    readTransform: (value) => value ?? '',
  } as SessionStorageUserPreferencesConfiguration<string | null>,

  lvhPrimerPaymentId: {
    sessionStorageKey: 'lvh_primer_payment_id',
    readTransform: (value) => value ?? '',
  } as SessionStorageUserPreferencesConfiguration<string | null>,

  navigationStack: {
    sessionStorageKey: 'lvh_nav_stack',
    readTransform: (value) => {
      try {
        return JSON.parse(value);
      } catch {
        return [];
      }
    },
    writeTransform: (value) => JSON.stringify(value),
    defaultValue: () => [],
  } as SessionStorageUserPreferencesConfiguration<NavigationStackItem[]>,
};
