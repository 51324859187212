/**
 * returns an object equal to the input without its top level functions.
 */
export function removeFunctionMembers<T extends object>(object: T): Partial<T> {
  return Object.entries(object)
    .filter(([, value]) => typeof value !== 'function')
    .reduce((acc, [key, value]) => {
      acc[key as keyof T] = value;

      return acc;
    }, {} as T);
}

/**
 * Utility function to debounce executing of the function
 * @param func debouncing function, could sync and async
 * @param ms debouncing delay, 0 by default
 */
export function staticDebounce<T extends any[], I>(func: (...args: T) => Promise<I> | I, ms = 0) {
  let timer: NodeJS.Timeout;

  return (...args: T) => {
    clearTimeout(timer);

    return new Promise<I>((resolve, reject) => {
      timer = setTimeout(async () => {
        try {
          const result = await func(...args);

          resolve(result);
        } catch (ex) {
          // Propagate any errors
          reject(ex);
        }
      }, ms);
    });
  };
}

/**
 * Utility function to debounce executing of the function
 * @param func debouncing function, could sync and async, this variant allows for the delay different on each debou
 */
export function configurableDebounce<T extends any[], I>(func: (...args: T) => Promise<I>) {
  let timer: NodeJS.Timeout;

  return (ms: number, ...args: T) => {
    clearTimeout(timer);

    return new Promise<I>((resolve, reject) => {
      timer = setTimeout(async () => {
        try {
          const result = await func(...args);

          resolve(result);
        } catch (ex) {
          // Propagate any errors
          reject(ex);
        }
      }, ms);
    });
  };
}
