import { FeatureFlagValue } from '@Core/octopus/types';
import { sendEvent } from '@Core/tracking/sendEvent';
import { ExperimentTrackingObject, TrackingEvent } from '@Core/tracking/types';

export const useExperimentTracking = () => {
  return (name: string, flag?: FeatureFlagValue) => {
    if (flag?.experimentId && flag?.variantId) {
      sendEvent<ExperimentTrackingObject>({
        event: TrackingEvent.experiment,
        experimentId: flag.experimentId,
        experimentKey: name,
        variationId: flag.variantId,
        variationValue: String(flag.value),
      });
    }
  };
};
