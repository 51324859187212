import {
  octopusLastUsedRevisionCookie,
  priceScopeTokensCookie,
} from '@loveholidays/skyline-cookies';
import { Exchange } from '@urql/core';
import UniversalCookie, { Cookie, CookieSetOptions } from 'universal-cookie';
import { pipe, tap } from 'wonka';

import { sendEvent } from '@Core/tracking/sendEvent';
import { ExperimentTrackingObject, TrackingEvent } from '@Core/tracking/types';

const setCookie = (
  cookies: UniversalCookie,
  name: string,
  value: Cookie,
  maxAge: CookieSetOptions['maxAge'],
) => {
  try {
    cookies.set(name, value, {
      maxAge,
      path: '/',
      httpOnly: false,
      sameSite: 'lax',
    });
  } catch {
    // In some cases we can't set cookies, e.g. when the response was already sent back
    // to the client on SSR. This can happen when we are making multiple parallel requests
    // to Aurora, and as a result of one of the responses we return a 404, and any other
    // responses which will complete after that would fail to set cookies, as the SSR response
    // was already sent to the client. In this case we just fail silently.
  }
};

interface AuroraExtensions {
  octopusRevision?: number;
  octopusEvid?: string;
  octopusActivations?: {
    experimentId: string;
    experimentKey: string;
    variationId: string;
    variationName: string;
    variationValue: string;
  }[];
  priceScopeTokens?: string[];
}

/**
 * Aurora returns meta data in the `extensions` field using the GraphQL extensions specification.
 * In this exchange we can handle this meta data accordingly.
 */
export const auroraExtensionsExchange =
  (cookies: UniversalCookie): Exchange =>
  ({ forward }) =>
  (ops$) =>
    pipe(
      ops$,
      forward,
      tap((res) => {
        const extensions: AuroraExtensions = res.extensions || {};

        // Setting "last used Octopus revision" cookie if it's empty
        if (extensions.octopusRevision && !cookies.get(octopusLastUsedRevisionCookie.name)) {
          setCookie(
            cookies,
            octopusLastUsedRevisionCookie.name,
            String(extensions.octopusRevision),
            octopusLastUsedRevisionCookie.expiry,
          );
        }
        // Send Experiment events for each activation
        (extensions.octopusActivations || []).forEach((activation) => {
          sendEvent<ExperimentTrackingObject>({
            event: TrackingEvent.experiment,
            ...activation,
          });
        });

        // Override "lps" cookie to store all the valid price scope tokens returned from Aurora
        if (extensions.priceScopeTokens) {
          setCookie(
            cookies,
            priceScopeTokensCookie.name,
            extensions.priceScopeTokens.join('|'),
            priceScopeTokensCookie.expiry,
          );
        }
      }),
    );
