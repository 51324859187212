import loadable from '@loadable/component';
import { useEffect } from 'react';

import { LoadableNonGenericSearchResultsPage } from './LoadableNonGenericSearchResultsPage';
import { LoadableGalleryModal } from '@Components/Modal/LoadableGalleryModal';
import { LoadableMasonryGridModal } from '@Components/NewGallery/LoadableMasonryModal';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { requestIdleCallback } from '@Core/requestIdleCallback';
import { LoadableGenericPage } from '@Pages/generic/LoadableGenericPage';

const loadableGenericSearchResultsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "LC-SearchResultsPageComponent" */ '@Components/LayoutComponents/SearchResultsPageComponent'
    ),
);

export const usePreloadSearchResultPage = () => {
  const isGenericSearchResultsPageEnabled = useFeatureFlag('GenericSRPPanda');
  const newProductCardGallery = useFeatureFlag('NewProductCardGallery');

  useEffect(() => {
    requestIdleCallback(() => {
      if (newProductCardGallery) {
        LoadableGalleryModal.preload();
      } else {
        LoadableMasonryGridModal.preload();
      }

      if (isGenericSearchResultsPageEnabled) {
        LoadableGenericPage.preload();
        loadableGenericSearchResultsPage.preload();
      } else {
        LoadableNonGenericSearchResultsPage.preload();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
