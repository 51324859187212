import dayjs, { Dayjs } from 'dayjs';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export const utcDate = (date: string, format: string = ISO_DATE_FORMAT): Dayjs =>
  dayjs.utc(date, format);

export const parseDate = (date: string, format: string) => {
  const dayjsDate = dayjs(date, format, true);

  return dayjsDate.isValid() ? dayjsDate : null;
};

export const formatDate = (
  locale: string,
  date: string,
  inputFormat: string,
  outputFormat: string,
) => dayjs(date, inputFormat).locale(locale).format(outputFormat);

export const formatCalendarDate = (
  locale: string,
  date: string | number | Dayjs,
  format: string,
) => {
  if (typeof date === 'number') {
    return dayjs(date).locale(locale).format(format);
  }

  if (typeof date === 'string') {
    return dayjs(date, ISO_DATE_FORMAT).locale(locale).format(format);
  }

  return date.locale(locale).format(format);
};
