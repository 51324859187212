import { defaultSearchSelection } from './defaultSearchSelection';
import type { SearchSelectionStore } from './types';
import { isEmpty } from '@Client/utils/isEmpty';

type NonFunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

type NoUndefined<T> = T extends undefined ? never : T;

type SearchSelectionData = Partial<
  Pick<SearchSelectionStore, NoUndefined<NonFunctionPropertyNames<SearchSelectionStore>>>
>;

const defaultSearchSelectionData: Partial<SearchSelectionData> = defaultSearchSelection;

function deepReset<T>(partial: Partial<T>, defaultState: Partial<T>, currentState: T): T {
  const newState = { ...currentState };

  for (const key in partial) {
    const k = key as keyof T;
    const value = partial[k];

    // If the value is empty, skip resetting this property
    if (isEmpty(value)) {
      continue;
    }

    if (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null &&
      defaultState[k] !== undefined
    ) {
      // Use deepReset recursively for nested objects
      newState[k] = deepReset(value, defaultState[k] as Partial<T[keyof T]>, currentState[k]);
    } else if (defaultState[k] !== undefined) {
      newState[k] = defaultState[k] as T[keyof T];
    } else {
      newState[k] = undefined as T[keyof T];
    }
  }

  return newState;
}

/**
 * Resets specified properties in the search selection state to their default values.
 *
 * The function takes the current `state` and a `searchSelection` object,
 * and returns a new state where properties specified in `searchSelection` are reset to their
 * default values as defined in `defaultSearchSelection` if their values are not empty.
 */
export function deselectSearchSelection(
  state: SearchSelectionData,
  searchSelection: Partial<SearchSelectionData>,
): SearchSelectionStore {
  const newState = { ...state };

  for (const key in searchSelection) {
    const k = key as keyof SearchSelectionData;
    const value = searchSelection[k];

    // If the value is empty, skip resetting this property
    if (isEmpty(value)) {
      continue;
    }

    if (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null &&
      defaultSearchSelectionData[k] !== undefined
    ) {
      // Use deepReset for nested objects like filters
      newState[k] = deepReset(value, defaultSearchSelectionData[k], state[k]) as any;
    } else if (defaultSearchSelectionData[k] !== undefined) {
      // For non-nested properties, reset to default
      newState[k] = defaultSearchSelectionData[k] as any;
    } else {
      // Property not in defaultSearchSelection, reset to undefined
      newState[k] = undefined;
    }
  }

  return newState as SearchSelectionStore;
}
