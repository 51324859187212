import { useParams } from 'react-router-dom';

import { useQueryParams } from '@Core/helpers/url';

export const getContentIdFromQueryParams = (name: string) => () => {
  const queryParams = useQueryParams();

  return queryParams[name];
};

export const getContentIdFromRouteParams = (name: string) => () => {
  const routeParams = useParams<Record<string, string>>();

  return routeParams[name];
};
