import React from 'react';

import { CurrencyProvider } from './useCurrency';
import { PriceQuoteUnitProvider } from './usePriceQuoteUnit';
import { useAppContext } from '@Client/context/contexts';

export const PricingContextProvider: React.FC = ({ children }) => {
  const { site, siteOverride } = useAppContext();
  const { currency, locale } = siteOverride || site;

  return (
    <CurrencyProvider
      locale={locale}
      currency={currency}
    >
      <PriceQuoteUnitProvider>{children}</PriceQuoteUnitProvider>
    </CurrencyProvider>
  );
};
