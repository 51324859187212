import React, { useContext, useState } from 'react';

import { useUserPreferences } from '@Client/context/UserPreferencesContext/UserPreferencesContext';
import { PriceQuoteUnit } from '@Constants/PriceQuoteUnit';

interface PriceQuoteUnitContextValue {
  priceQuoteUnit: PriceQuoteUnit;
  setPriceQuoteUnit: (unit: PriceQuoteUnit) => void;
}

export const PriceQuoteUnitContext = React.createContext<PriceQuoteUnitContextValue>({
  priceQuoteUnit: 'PER_PERSON',
  setPriceQuoteUnit: () => null,
});

export const PriceQuoteUnitProvider: React.FC = ({ children }) => {
  const { priceQuoteUnit } = useUserPreferences();
  const [unit, setUnit] = useState(priceQuoteUnit.get());

  return (
    <PriceQuoteUnitContext.Provider
      value={{
        priceQuoteUnit: unit,
        setPriceQuoteUnit(value: PriceQuoteUnit) {
          priceQuoteUnit.set(value);
          setUnit(value);
        },
      }}
    >
      {children}
    </PriceQuoteUnitContext.Provider>
  );
};

export const usePriceQuoteUnit = (): PriceQuoteUnitContextValue =>
  useContext(PriceQuoteUnitContext);
