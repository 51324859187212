import create, { GetState, SetState, State, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  subscribeWithSelector,
  StoreApiWithSubscribeWithSelector,
} from './zustandSubscribeWithSelector';

/**
 * Helper to make store types correct by including the `subscribeWithSelector` changes.
 * https://github.com/pmndrs/zustand#using-subscribe-with-selector
 */
export type Store<T extends State> = UseBoundStore<T, StoreApiWithSubscribeWithSelector<T>>;

/**
 * Helper to make zustand store creation easier,
 * including the `devtools` and the `subscribeWithSelector` middlewares.
 */
export const createStore = <T extends State>(
  fn: (set: SetState<T>, get: GetState<T>, api: StoreApi<T>) => T,
  name: string,
): Store<T> => {
  // In prod mode the `devtools` middleware is disabled
  if (process.env.NODE_ENV === 'development' && process.env.LEGACY_BROWSER !== 'true') {
    return create(devtools(subscribeWithSelector(fn), { name }));
  }

  return create(subscribeWithSelector(fn));
};
