import UniversalCookie from 'universal-cookie';

import { CookieUserPreferencesConfiguration, UserPreferenceStorage } from './interfaces';
import {
  isMigratedToMultiSite,
  readMultiSitePreference,
  writeMultiSitePreference,
} from './MultiSiteTransformer';
import { AppContext } from '@Server/handlers/handle-contexts/types';

export class CookieStorage implements UserPreferenceStorage<any> {
  private readonly cookies: UniversalCookie;

  private readonly context: AppContext;

  constructor(cookies: UniversalCookie, context: AppContext) {
    this.cookies = cookies;
    this.context = context;
  }

  read({ cookie, isMultiSite }: CookieUserPreferencesConfiguration<any>) {
    if (typeof cookie.name === 'undefined') {
      throw new Error('Cookie name is undefined');
    }

    const value = this.cookies.get(cookie.name);

    // If a shared cookie value is multi site in horizon but not in sunrise
    // we need to capture this and return null for the moment
    // until both platforms have parity
    if (!isMultiSite) {
      return isMigratedToMultiSite(value) ? undefined : value;
    }

    return readMultiSitePreference(value, this.context);
  }

  write(
    { cookie, isMultiSite }: CookieUserPreferencesConfiguration<any>,
    value: string,
    forceUpdate: boolean = false,
  ) {
    if (typeof cookie.name === 'undefined') {
      throw new Error('Cookie name is undefined');
    }

    let currentValue = this.cookies.get(cookie.name);
    if (typeof currentValue !== 'string') {
      currentValue = JSON.stringify(currentValue);
    }

    const payload = isMultiSite
      ? writeMultiSitePreference(value, this.context, currentValue)
      : value;

    // Do not update if the current value is the same
    if (!forceUpdate && currentValue === String(payload)) {
      return;
    }

    this.cookies.set(cookie.name, String(payload), {
      path: '/',
      httpOnly: false,
      ...(cookie.expiry && { maxAge: cookie.expiry }),
      ...(cookie.crossSite
        ? {
            sameSite: 'none',
            secure: true,
          }
        : {
            sameSite: 'lax',
          }),
    });
  }

  remove({ cookie }: CookieUserPreferencesConfiguration<any>) {
    if (typeof cookie.name === 'undefined') {
      throw new Error('Cookie name is undefined');
    }

    this.cookies.remove(cookie.name);
  }
}
