import { sendEvent } from '../sendEvent';
import { TrackingEvent, EcommerceTrackingObject, EcommerceProduct, ActionField } from '../types';
import { useSearchAvailabilityStore } from '@Stores/StoreContext';

// https://developers.google.com/tag-manager/enhanced-ecommerce#product-clicks
export const useProductClickTracking = () => {
  const searchId = useSearchAvailabilityStore(({ searchId }) => searchId);

  return (products: EcommerceProduct[], actionField?: ActionField, eventCallback?: () => void) => {
    const trackingObject: EcommerceTrackingObject = {
      event: TrackingEvent.productClick,
      list: actionField?.list,
      lastSearchId: searchId,
      ...products[0],
      eventCallback,
    };

    sendEvent(trackingObject);
  };
};
