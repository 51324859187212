import { useTheme } from '@emotion/react';

import { HeadingVariants, TextVariants, ThemeDefinition, TypographyVariants } from './types';

export const useThemeVariant = (
  themeKey?: Extract<keyof ThemeDefinition, 'text' | 'heading' | 'typography'>,
) => {
  const theme = useTheme();

  return (variant?: keyof TextVariants | keyof HeadingVariants | keyof TypographyVariants) =>
    themeKey && variant ? (theme[themeKey] as any)[variant] : {};
};
