import { MultiSitePreference } from './interfaces';
import { AppContext } from '@Server/handlers/handle-contexts/types';

// Global / multi-site support
//
// We should seamlessly migrate global preference values to
// multi-site values without consumers of these values knowledge
export type MigrateMultiSitePreference<T> = MultiSitePreference<T> | string;

export const MULTI_SITE_VALUE_PREFIX = '%multiSite__';

const safeParse = (value?: string): any => {
  if (typeof value === 'undefined') {
    return;
  }

  // This try catch will attempt to parse the preference value,
  // if this parse fails we will fallback to returning the
  // original value, as we can consider this a global preference value,
  // and will be migrated to the multi-site format
  // whn the new value is written to it
  try {
    return JSON.parse(value);
  } catch (ex) {
    return value;
  }
};

export const isMigratedToMultiSite = (value?: string): boolean => {
  if (typeof value === 'undefined' || typeof value !== 'string') {
    return false;
  }

  const [, multiSitePreference] = value.split(MULTI_SITE_VALUE_PREFIX);

  return !!multiSitePreference;
};

export const readMultiSitePreference = <T>(
  value: string | undefined,
  { site: { siteCode } }: AppContext,
): T | string | undefined => {
  if (typeof value === 'undefined') {
    return;
  }

  // if the preference value has prefix,
  // multi site preference value will be on the right,
  // if not then the global value will be present on the left
  const [globalPreference, multiSitePreference] = value.split(MULTI_SITE_VALUE_PREFIX);

  if (!multiSitePreference) {
    return globalPreference;
  }

  // If the preference is multi site, parse and access on siteCode
  const preference = safeParse(multiSitePreference) as MultiSitePreference<T>;

  return preference && preference[siteCode];
};

export const readMultiSitePreferenceAllSites = <T>(value: string | undefined) => {
  if (typeof value === 'undefined') {
    return;
  }

  // if the preference value has prefix,
  // multi site preference value will be on the right,
  // if not then the global value will be present on the left
  const [, multiSitePreference] = value.split(MULTI_SITE_VALUE_PREFIX);

  if (!multiSitePreference) {
    return;
  }

  // If the preference is multi site, parse and access on siteCode
  const preference = safeParse(multiSitePreference) as MultiSitePreference<T>;

  return preference;
};

export const writeMultiSitePreference = <T>(
  value: T,
  { site: { siteCode } }: AppContext,
  current: string = '',
): string => {
  // if the preference value has prefix,
  // multi site preference value will be on the right,
  // if not then the global value will be present on the left
  const [global, multiSite] = current.split(MULTI_SITE_VALUE_PREFIX);

  // Attempt to parse current preference value
  const globalPreference = safeParse(global);
  const multiSitePreference = safeParse(multiSite);

  // Construct a new multi-site snapshot
  // based on the current and new values
  const snapshot: MultiSitePreference<T> = {
    // Fold in existing sites if present
    ...multiSitePreference,
    // migrate global preference to GB site
    // if a non GB site is accessed with a global preference
    ...(globalPreference && { GB: globalPreference }),
    // add / update value for siteCode
    [siteCode]: value,
  };

  return `${MULTI_SITE_VALUE_PREFIX}${JSON.stringify(snapshot)}`;
};
