import { useInViewTracking } from './useInViewTracking';
import { TrackingEvent, EcommerceTrackingObject, EcommerceProductImpression } from '../types';
import { useSearchAvailabilityStore } from '@Stores/StoreContext';

// https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
export const useProductImpressionTracking = (
  product: EcommerceProductImpression,
  disabled?: boolean,
) => {
  const searchId = useSearchAvailabilityStore(({ searchId }) => searchId);

  const trackingObject: EcommerceTrackingObject = {
    event: TrackingEvent.productImpression,
    lastSearchId: searchId,
    ...product,
  };

  return useInViewTracking(trackingObject, disabled);
};
