import { SetState } from 'zustand';

import { AlternativeFlightFilters } from '@AuroraTypes';
import { createStore } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';

export type FilterSelection = string[] | number | undefined;
export type FilterSelections = Record<keyof AlternativeFlightFilters, FilterSelection>;

export interface FlightSelectionStore {
  filters: FilterSelections;
  selectedAirportTab?: string;
  offersStart: number;
  stepSelection: 'inbound' | 'outbound';
  selectedOutboundFlightKey?: string;
  page: number;

  setInitialValues: (overrides?: Partial<FlightSelectionStore>) => void;
  setFilters: (filters: Partial<FilterSelections>) => void;
  setFilterSelection: (filterId: keyof AlternativeFlightFilters, value: FilterSelection) => void;
  setStepperFilterSelection: (filterId: keyof AlternativeFlightFilters, value: number) => void;
  resetFilters: () => void;
  setSelectedAirportTab: (id?: string) => void;
  setOffersStart: (offersStart: number) => void;
  setStepSelection: (step: 'inbound' | 'outbound') => void;
  setSelectedOutboundFlightKey: (key: string | undefined) => void;
  setPage: (page: number) => void;
}

const reducer =
  (set: SetState<FlightSelectionStore>, fieldName: keyof FlightSelectionStore) =>
  (value: unknown) =>
    set((state: FlightSelectionStore) => ({ ...state, [fieldName]: value }));

const defaultFilterValues: FilterSelections = {
  airportPreferences: [],
  arrivalPreferences: [],
  cancellationPolicy: [],
  carriers: [],
  departureAirports: [],
  duration: undefined,
  inboundTimes: [],
  outboundTimes: [],
  stops: [],
  includedCheckedBags: 0,
};

export const createFlightSelectionStore = (initialValues?: Partial<FlightSelectionStore>) =>
  createStore<FlightSelectionStore>(
    (set, get) => ({
      filters: {
        ...defaultFilterValues,
      },
      offersStart: 0,
      stepSelection: 'outbound',
      selectedOutboundFlightKey: undefined,
      page: 0,
      ...initialValues,

      setInitialValues: (overrides?: Partial<FlightSelectionStore>) => {
        const extended = createFlightSelectionStore(overrides).getState();
        set(removeFunctionMembers(extended) as FlightSelectionStore);
      },

      setFilters: (filters: Partial<FilterSelections>) => {
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            ...filters,
          },
        }));
      },
      setFilterSelection: (filterId, value) =>
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            [filterId]: value,
          },
        })),
      setStepperFilterSelection: (filterId, value) =>
        get().setFilterSelection(filterId, value !== undefined ? Number(value) : undefined),
      resetFilters: () => {
        set((state) => ({
          filters: {
            ...defaultFilterValues,
            cancellationPolicy: state.filters.cancellationPolicy,
            includedCheckedBags: state.filters.includedCheckedBags,
          },
        }));
      },
      setSelectedAirportTab: reducer(set, 'selectedAirportTab'),
      setOffersStart: reducer(set, 'offersStart'),
      setStepSelection: reducer(set, 'stepSelection'),
      setSelectedOutboundFlightKey: reducer(set, 'selectedOutboundFlightKey'),
      setPage: reducer(set, 'page'),
    }),
    'FlightSelectionStore',
  );
