import { useInView } from '@loveholidays/design-system';
import { useCallback, useRef } from 'react';

import { sendEvent } from '../sendEvent';
import { TrackingEvent, EcommerceTrackingObject, EcommerceProductImpression } from '../types';

export interface CheckoutProductImpressionTrackingRef {
  [uniqId: string]: boolean;
}

/**
 * TODO This is a hack for checkout,
 * because we have double events.
 * it's need to remove when we fix it.
 * and use useProductImpressionTracking instead
 * just remove uniqId from all usages. ❤️
 */

// https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
export const useCheckoutProductImpressionTracking = (
  product: EcommerceProductImpression,
  uniqId: string,
) => {
  const memory = useRef<CheckoutProductImpressionTrackingRef>({});

  const trackingObject: EcommerceTrackingObject = {
    event: TrackingEvent.productImpression,
    ...product,
  };

  const sendEventTracking = useCallback(
    (data: EcommerceTrackingObject, uniqId: string) => {
      if (memory.current[uniqId]) {
        return;
      }

      sendEvent(data);
      memory.current[uniqId] = true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memory.current],
  );

  // TODO this is copy from useInViewTracking
  // When we remove uniqId, it need to return useInViewTracking
  const ref = useInView(
    (isInView) => {
      if (isInView) {
        sendEventTracking(trackingObject, uniqId);
      }
    },
    {
      triggerOnlyOnce: true,
      threshold: 0.45,
    },
  );

  return ref;
};
