import { retryExchange as retry } from '@urql/exchange-retry';

/**
 * Shared retry exchange for both server and client
 */
export const retryExchange = retry({
  initialDelayMs: 0,
  randomDelay: false,
  maxNumberAttempts: 2,
  retryIf: (error) => {
    // Do not retry when Datadome is blocking the request
    if (error.response?.status === 403) {
      return false;
    }

    // Retry for network errors
    if (error.networkError) {
      return true;
    }

    // Retry for any GraphQL errors, including bad API responses
    if (error.graphQLErrors.length) {
      return true;
    }

    return false;
  },
});
