/* eslint-disable class-methods-use-this */
import { LocalStorageUserPreferencesConfiguration, UserPreferenceStorage } from './interfaces';
import {
  isMigratedToMultiSite,
  readMultiSitePreference,
  readMultiSitePreferenceAllSites,
  writeMultiSitePreference,
} from './MultiSiteTransformer';
import { AppContext } from '@Server/handlers/handle-contexts/types';

export class LocalStorage implements UserPreferenceStorage<any> {
  private readonly context: AppContext;

  constructor(context: AppContext) {
    this.context = context;
  }

  read({ localStorageKey, isMultiSite }: LocalStorageUserPreferencesConfiguration<any>): any {
    if (typeof window === 'undefined') {
      throw new Error('LocalStorage access does not work on SSR');
    } else if (!localStorageKey) {
      throw new Error(`Invalid LocalStorage key: "${localStorageKey}"`);
    }

    const value = this.readRaw(localStorageKey);

    // If a shared local storage value is multi site in horizon but not in sunrise
    // we need to capture this and return null for the moment
    // until both platforms have parity
    if (!isMultiSite) {
      return isMigratedToMultiSite(value) ? undefined : value;
    }

    return readMultiSitePreference(value, this.context);
  }

  readAllSites(localStorageKey: string) {
    if (typeof window === 'undefined') {
      throw new Error('LocalStorage access does not work on SSR');
    } else if (!localStorageKey) {
      throw new Error(`Invalid LocalStorage key: "${localStorageKey}"`);
    }

    const value = this.readRaw(localStorageKey);

    return readMultiSitePreferenceAllSites(value);
  }

  write(
    { localStorageKey, isMultiSite }: LocalStorageUserPreferencesConfiguration<any>,
    value: string,
    forceUpdate: boolean = false,
  ) {
    if (typeof window === 'undefined') {
      throw new Error('LocalStorage access does not work on SSR');
    } else if (!localStorageKey) {
      throw new Error(`Invalid LocalStorage key: "${localStorageKey}"`);
    }

    try {
      let currentValue = this.readRaw(localStorageKey);
      if (typeof currentValue !== 'string') {
        currentValue = JSON.stringify(currentValue);
      }

      const payload = isMultiSite
        ? writeMultiSitePreference(value, this.context, currentValue)
        : value;

      // Do not update if the current value is the same
      if (!forceUpdate && currentValue === payload) {
        return;
      }

      window.localStorage.setItem(localStorageKey, payload);
    } catch {
      // Do nothing when can't save to LocalStorage
    }
  }

  remove({ localStorageKey }: LocalStorageUserPreferencesConfiguration<any>) {
    if (typeof window === 'undefined') {
      throw new Error('LocalStorage access does not work on SSR');
    } else if (!localStorageKey) {
      throw new Error(`Invalid LocalStorage key: "${localStorageKey}"`);
    }

    window.localStorage.removeItem(localStorageKey);
  }

  private readRaw(localStorageKey: string) {
    try {
      return window.localStorage.getItem(localStorageKey) ?? undefined;
    } catch {
      // Returning undefined so we can fall back to use the default value provided
      return;
    }
  }
}
