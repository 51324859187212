import React from 'react';

import { ClassNameProps, HTMLElementProps } from '@ComponentProps';
import { TextVariants } from '@UX/themes/types';
import { useThemeVariant } from '@UX/themes/useThemeVariant';

interface TextProps extends ClassNameProps, HTMLElementProps {
  variant?: keyof TextVariants;
}

export const Text: React.FC<TextProps> = ({
  as: Wrapper = 'p',
  className,
  children,
  variant = 'regular',
  ...props
}) => {
  const getThemeVariant = useThemeVariant('text');

  return (
    <Wrapper
      className={className}
      sx={getThemeVariant(variant)}
      {...props}
    >
      {children}
    </Wrapper>
  );
};
