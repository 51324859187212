import loadable from '@loadable/component';
import { Skeleton } from '@loveholidays/design-system';
import React from 'react';

export const LoadableNonGenericSearchResultsPage = loadable(
  () => import('@Pages/search-results/SearchResultsPage'),
  {
    fallback: <Skeleton sx={{ height: '100vh' }} />,
  },
);
