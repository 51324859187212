import React, { createContext, useContext, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';
import { StateSelector } from 'zustand';

import getCombinedFeatureFlagsForPages from './getCombinedFeatureFlagsForPages.gql';
import { PageType, Query, Scalars } from '@AuroraTypes';
import { Site } from '@AuroraTypes';
import { getPageTypeClientSync, useAppContext } from '@Contexts/contexts';
import { createStore, Store } from '@Core/createStore';
import { captureClientError } from '@Core/errors/errors';
import { FeatureFlagValue, FeatureFlagValues } from '@Core/octopus/types';
import { sendEvent } from '@Core/tracking/sendEvent';
import {
  ErrorEventType,
  ErrorWebEventTrackingObject,
  TrackingEvent,
  WebEventCategory,
} from '@Core/tracking/types';
import { supportedPageTypes } from '@Server/routes/main/routes';
import { equalityFn } from '@Stores/equalityFn';

type CombinedFeatureFlagsByPageTypes = Scalars['CombinedFeatureFlagsByPageTypes'];

type Status = 'default' | 'fetching' | 'error';

/**
 * Feature flags to be reported but not otherwise actioned on every page load.
 *
 * Every feature flag here must be set for all page types.
 */
export const GLOBAL_REPORTED_FEATURE_FLAGS = [
  // Send tracking events for holdOut if user is assigned to holdOut group
  'holdOut',

  // Send tracking events for Trustpilot as the footer is not hydrated so wouldn't send
  // tracking.
  'Trustpilot',

  // Rudderstack V3 SDK
  'RudderstackV3',
];

/**
 * Feature flags that can be used before we know the pageType.
 *
 * Every feature flag here must be set for all page types.
 */
const GLOBALLY_SET_FEATURE_FLAGS = [...GLOBAL_REPORTED_FEATURE_FLAGS, 'DynamicPageMeta'];

// -- Store
interface FeatureFlagStore {
  flags: (props: {
    name?: string;
    site: Pick<Site, 'pathPrefix'>;
    location: Pick<ReturnType<typeof useLocation>, 'pathname'>;
    originalPageType: PageType;
  }) => FeatureFlagValues | undefined;
  flag: (props: {
    name: string;
    site: Pick<Site, 'pathPrefix'>;
    location: Pick<ReturnType<typeof useLocation>, 'pathname'>;
    originalPageType: PageType;
  }) => FeatureFlagValue | undefined;
  initialFlags: FeatureFlagValues;
  flagsByPageTypes: CombinedFeatureFlagsByPageTypes;
  status: Status;
  setStatus: (status: Status) => void;

  setFlagsByPageTypes: (flagsByPages: CombinedFeatureFlagsByPageTypes) => void;
}

const createFeatureFlagStore = (initialFlags: FeatureFlagValues = {}) =>
  createStore<FeatureFlagStore>(
    (set, get) => ({
      flags: ({ name, site: { pathPrefix }, location: { pathname }, originalPageType }) => {
        const flagsByPageTypes = get().flagsByPageTypes;

        if (Object.keys(flagsByPageTypes).length === 0) {
          return initialFlags;
        }

        const pageType = getPageTypeClientSync(`${pathPrefix}${pathname}`);
        if (pageType === undefined) {
          sendEvent<ErrorWebEventTrackingObject>({
            event: TrackingEvent.webEvent,
            category: WebEventCategory.error,
            errorType: 'featureFlagBeforePageTypeAvailable' as ErrorEventType,
            action: 'create-feature-flag-store',
            label: 'Requested feature flag before pageType is available on path',
            pathPrefix,
            pathname,
            name,
          });

          return initialFlags;
        }

        // Because VR and integration tests get realistic initialFlags, but then blank flagsByPageTypes
        if (originalPageType === pageType) {
          return initialFlags;
        }

        if (!flagsByPageTypes[pageType]) {
          captureClientError('Requested feature flags for unsupported pageType', {
            pathPrefix,
            pathname,
            name,
            pageType,
          });

          // Fallback to initial flags as its better than breaking completely
          return initialFlags;
        }

        return flagsByPageTypes[pageType];
      },
      flag: ({ name, site, location, originalPageType }) => {
        // These feature flags get checked before we know the page type
        if (GLOBALLY_SET_FEATURE_FLAGS.includes(name)) {
          return initialFlags[name];
        }

        const flags = get().flags({ name, site, location, originalPageType });

        return flags?.[name];
      },
      initialFlags,
      flagsByPageTypes: {},
      status: 'default',
      setStatus: (status) => set({ status }),

      setFlagsByPageTypes: (flagsByPageTypes) =>
        set(() => ({
          status: 'default',
          flagsByPageTypes,
        })),
    }),
    'FeatureFlagStore',
  );

// -- Context
type FeatureFlagStoreContextValue = Store<FeatureFlagStore>;

const FeatureFlagStoreContext = createContext<FeatureFlagStoreContextValue>(
  {} as FeatureFlagStoreContextValue,
);

interface FeatureFlagStoreProviderProps {
  initialFlags?: FeatureFlagValues;
}

const AGENT_SITE_CODE = 'AGENT';

export const FeatureFlagStoreProvider: React.FC<FeatureFlagStoreProviderProps> = ({
  children,
  initialFlags,
}) => {
  const { site } = useAppContext();
  const store = useRef(createFeatureFlagStore(initialFlags));
  const [{ data, fetching, error }] = useQuery<Query>({
    query: getCombinedFeatureFlagsForPages,
    variables: {
      pageTypes: supportedPageTypes,
    },
    pause:
      typeof window === 'undefined' ||
      process.env.NODE_ENV === 'test' ||
      /**
       * For Agent website we want the experiments fetched with x-site AGENT.
       * This is different to how most fetches to Aurora
       * now work with regards to AGENT.
       * Blocking it here causes feature-flag-store to fall back to the
       * server fetched initial-flags. This works, so long as we never
       * run experiments on the agent site.
       */
      site.siteCode === AGENT_SITE_CODE,
  });

  if (error) {
    store.current.getState().setStatus('error');
  }

  if (fetching) {
    store.current.getState().setStatus('fetching');
  }

  if (!fetching && data) {
    const { combinedFeatureFlagsByPageTypes } = data.FeatureFlags;
    store.current.getState().setFlagsByPageTypes(combinedFeatureFlagsByPageTypes);
    // eslint-disable-next-line no-underscore-dangle
    window.__CONTEXTS__.FeatureFlagsByPageTypes = combinedFeatureFlagsByPageTypes;
  }

  return useMemo(
    () => (
      <FeatureFlagStoreContext.Provider value={store.current}>
        {children}
      </FeatureFlagStoreContext.Provider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export const useFeatureFlagStore: <U>(selector: StateSelector<FeatureFlagStore, U>) => U = (
  selector,
) => useContext(FeatureFlagStoreContext)(selector, equalityFn);
