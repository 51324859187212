export interface ConnectionInfo {
  dataSaverEnabled: boolean;
  effectiveConnectionType: EffectiveConnectionType | 'unknown';
  speed: 'slow' | 'fast';
}

const slowConnections: ConnectionInfo['effectiveConnectionType'][] = ['slow-2g', '2g'];

export const detectConnection = (): ConnectionInfo => {
  const connection = (
    typeof window === 'undefined' ? {} : navigator.connection || {}
  ) as NetworkInformation;

  const dataSaverEnabled = !!connection.saveData;
  const effectiveConnectionType = connection.effectiveType || 'unknown';

  return {
    dataSaverEnabled,
    effectiveConnectionType,
    speed: slowConnections.includes(effectiveConnectionType) || dataSaverEnabled ? 'slow' : 'fast',
  };
};
