import { URLQueryParams } from '@loveholidays/design-system';

import { ErrorEventType, ErrorEventTypePromMetric, TrackingEvent } from '../types';
import { SiteCode } from '@AuroraTypes';
import { useAppContext } from '@Contexts/contexts';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { sendEvent } from '@Core/tracking/sendEvent';

export interface ClientEventOptions {
  type: keyof typeof ErrorEventType;
  subType?: string;
  pageType?: string;
  siteCode?: SiteCode;
}

/**
 * Client events (e.g. "no results") are reported to Rudderstack, but to have more real-time
 * data we send these events to Sunrise as well so we can report that to Prometheus and set up
 * some alerts.
 */
export const sendClientEvent = ({
  type,
  subType = '',
  pageType = '',
  siteCode,
}: ClientEventOptions) => {
  // Do not do this on SSR
  if (typeof window === 'undefined') {
    return;
  }

  // Sanitize pageType
  if (!pageType) {
    pageType = '';
  }

  // Param parameters into query parameters
  const params = new URLQueryParams();

  if (siteCode) {
    params.set('siteCode', siteCode);
  }
  if (type) {
    params.set('type', type);
  }
  if (subType) {
    params.set('subType', subType);
  }
  if (pageType) {
    params.set('pageType', pageType);
  }

  // Construct url
  const url = `/client-events?${params.toString()}`;

  try {
    if (!('sendBeacon' in navigator)) {
      throw new Error('sendBeacon not supported');
    }

    // https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
    navigator.sendBeacon.bind(navigator)(url);
  } catch {
    fetch(url, {
      method: 'post',
    });
  }
};

const errorEventTypeToPromMetricMap: Record<ErrorEventType, ErrorEventTypePromMetric> = {
  [ErrorEventType.noAvailability]: ErrorEventTypePromMetric.noAvailability,
  [ErrorEventType.noAccomodationFound]: ErrorEventTypePromMetric.noAccomodationFound,
  [ErrorEventType.errorPageShown]: ErrorEventTypePromMetric.errorPageShown,
  [ErrorEventType.checkoutErrorPageShown]: ErrorEventTypePromMetric.checkoutErrorPageShown,
  [ErrorEventType.searchAssertionError]: ErrorEventTypePromMetric.searchAssertionError,
  [ErrorEventType.failedPayment]: ErrorEventTypePromMetric.failedPayment,
  [ErrorEventType.checkoutPartnerInteraction]: ErrorEventTypePromMetric.checkoutPartnerInteraction,
  [ErrorEventType['client-captcha']]: ErrorEventTypePromMetric['client-captcha'],
  [ErrorEventType['client-captcha-invalid-data']]:
    ErrorEventTypePromMetric['client-captcha-invalid-data'],
  [ErrorEventType['client-captcha-invalid-json']]:
    ErrorEventTypePromMetric['client-captcha-invalid-json'],
};

// Only supports counters right now
export const sendEventsPromEvent = ({
  siteCode,
  type,
  pageType,
  subType,
}: {
  siteCode: SiteCode;
  type: keyof typeof ErrorEventType;
  pageType?: string;
  subType?: string;
}) => {
  const labels = {
    site_code: siteCode,
    ...(pageType ? { page_type: pageType } : {}),
    ...(subType ? { sub_type: subType } : {}),
  };

  const eventType: ErrorEventType = ErrorEventType[type];
  const promMetricName = eventType ? errorEventTypeToPromMetricMap[eventType] : type;

  sendEvent({
    event: TrackingEvent.eventsProm,
    metric_name: promMetricName,
    metric_value: 1,
    labels,
  });
};

export const useClientEventTracking = () => {
  const {
    site: { siteCode },
  } = useAppContext();
  const shouldSendToEventsProm = useFeatureFlag('EnableEventsProm');

  return (options: Omit<ClientEventOptions, 'siteCode'>) => {
    sendClientEvent({
      ...options,
      siteCode,
    });

    if (shouldSendToEventsProm) {
      sendEventsPromEvent({
        ...options,
        siteCode,
      });
    }
  };
};
