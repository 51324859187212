import { sendEvent } from '../sendEvent';
import { TrackingEvent, EcommerceTrackingObject, EcommerceProduct, ActionField } from '../types';
import { useSearchAvailabilityStore } from '@Stores/StoreContext';

export const useProductDetailsViewTracking = () => {
  const searchId = useSearchAvailabilityStore(({ searchId }) => searchId);

  return (products: EcommerceProduct[], actionField?: ActionField) => {
    const trackingObject: EcommerceTrackingObject = {
      event: TrackingEvent.productDetailsView,
      list: actionField?.list,
      lastSearchId: searchId,
      ...products[0],
    };

    sendEvent(trackingObject);
  };
};
