import { isNoMotionPreferred } from '@Core/isNoMotionPreferred';

export function windowScrollTo({ left, top, behavior }: ScrollToOptions) {
  try {
    window.scrollTo({ left, top, behavior: isNoMotionPreferred() ? 'auto' : behavior });
  } catch (e) {
    // Older browsers understand `scrollTo` but do not understand the option parameter form of
    // it. https://sentry.io/organizations/loveholidays-wz/issues/2688547427/?project=5177787
    if (e instanceof TypeError) {
      window.scrollTo(left ?? window.scrollX, top ?? window.scrollY);
    } else {
      throw e;
    }
  }
}
