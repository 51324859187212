import { sendClientEvent } from './tracking/hooks/useClientEventTracking';
import { ErrorEventType } from './tracking/types';

/**
 * When DataDome block a response, it returns 403 with a JSON body containing a `url` field.
 * We can manually trigger the captcha page by calling the `displayDataDomeCaptchaPage`
 * function exposed on `window` by the DataDome script.
 * This runs outside of React, do not use any hooks here!
 */
export const fetchWithDataDome: typeof fetch = async (url, options?: RequestInit) => {
  const { operationName } = typeof options?.body === 'string' && JSON.parse(options.body);

  const path =
    process.env.NODE_ENV === 'development' ? `${url}&operationName=${operationName}` : url;

  const res = await fetch(path, options);

  if (res.status === 403) {
    const body = await res.json().catch((e) => {
      sendClientEvent({
        type: ErrorEventType['client-captcha-invalid-json'],
        siteCode: window.__CONTEXTS__.App.site.siteCode,
      });

      throw e;
    });

    if (body.url) {
      sendClientEvent({
        type: ErrorEventType['client-captcha'],
        siteCode: window.__CONTEXTS__.App.site.siteCode,
      });

      window.displayDataDomeCaptchaPage?.(body.url);

      return new Promise(() => {});
    } else {
      sendClientEvent({
        type: ErrorEventType['client-captcha-invalid-data'],
        siteCode: window.__CONTEXTS__.App.site.siteCode,
      });
    }
  }

  return res;
};
