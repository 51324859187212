import { Exchange, OperationResult, CombinedError } from '@urql/core';
import { pipe, tap } from 'wonka';

export interface OperationErrorResult extends OperationResult {
  error: CombinedError;
}

interface TapErrorExchangeOptions {
  onError: (err: OperationErrorResult) => void;
}

const isOperationErrorResult = (res: OperationResult): res is OperationErrorResult =>
  Boolean(res.error);

const isDataDomeResponse = (res: OperationErrorResult) => res.error?.response?.status === 403;

export const tapErrorExchange =
  ({ onError }: TapErrorExchangeOptions): Exchange =>
  ({ forward }) =>
  (ops$) =>
    pipe(
      ops$,
      forward,
      tap((res) => {
        if (isOperationErrorResult(res) && !isDataDomeResponse(res)) {
          onError(res);
        }
      }),
    );
