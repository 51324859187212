export const flattenObjectValues = <T extends Record<string, any>>(
  object: Record<string, any>,
): T =>
  Object.entries(object).reduce((acc, [key, value]) => {
    acc[key as keyof T] =
      Array.isArray(value) && value.every((val) => typeof val !== 'object')
        ? value.join(',')
        : value;

    return acc;
  }, {} as T);

export const cleanObject = (data: Record<string, any>) =>
  JSON.parse(JSON.stringify(data, (key, value) => (key === '__typename' ? undefined : value)));
