import { SetState } from 'zustand';

import { AlternativeFlightFilters, OfferDetails } from '@AuroraTypes';
import { createStore } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';

export interface FlightAvailabilityStore {
  fetching: boolean;
  paginating: boolean;
  filters: Partial<AlternativeFlightFilters>;
  airportTabs: string[];
  currentAirportTab?: string;
  offers: OfferDetails[];
  totalOffers: number;
  cheapestPrice?: number;

  setOffers: (offers: OfferDetails[]) => void;
  setFilters: (filters: AlternativeFlightFilters) => void;
  setAirportTabs: (ids: string[]) => void;
  setInitialValues: (overrides?: Partial<FlightAvailabilityStore>) => void;
  hasValidFilters: () => boolean;
}

const reducer =
  (set: SetState<FlightAvailabilityStore>, fieldName: keyof FlightAvailabilityStore) =>
  (value: unknown) =>
    set((state: FlightAvailabilityStore) => ({ ...state, [fieldName]: value }));

export const defaultFilterValues: FlightAvailabilityStore['filters'] = {
  airportPreferences: [],
  arrivalPreferences: [],
  cancellationPolicy: [],
  carriers: [],
  departureAirports: [],
  duration: {
    min: 0,
    max: 0,
  },
  inboundTimes: [],
  outboundTimes: [],
  stops: [],
  includedCheckedBags: {
    min: 0,
    max: 0,
  },
};

export const createFlightAvailabilityStore = (initialValues?: Partial<FlightAvailabilityStore>) =>
  createStore<FlightAvailabilityStore>(
    (set, get) => ({
      fetching: false,
      paginating: false,
      airportTabs: [],
      offers: [],
      totalOffers: 0,
      ...initialValues,
      filters: {
        ...defaultFilterValues,
        ...initialValues?.filters,
      },

      setOffers: reducer(set, 'offers'),
      setFilters: reducer(set, 'filters'),
      setAirportTabs: reducer(set, 'airportTabs'),
      setInitialValues: (overrides?: Partial<FlightAvailabilityStore>) => {
        const extended = createFlightAvailabilityStore(overrides).getState();
        set(removeFunctionMembers(extended) as FlightAvailabilityStore);
      },
      hasValidFilters: () => {
        const { filters } = get();

        return Object.values(filters).some((filterOption) =>
          Array.isArray(filterOption)
            ? filterOption.length > 0
            : filterOption.min !== filterOption.max,
        );
      },
    }),
    'FlightAvailabilityStore',
  );
