import { Dayjs, isDayjs } from 'dayjs';

import { RoomConfiguration, RoomConfigurationInput, SearchSelection } from '@AuroraTypes';
import { URLQueryParams } from '@Core/url/URLQueryParams';
import { ISO_DATE_FORMAT } from '@Dates/dates';

export const stringifyRooms = (rooms: RoomConfigurationInput[]) =>
  rooms.map(({ adults, childAges = [] }) => [adults, ...childAges].join('-')).join(',');

export const mapCancellationPolicies = (options?: string[]) =>
  options?.length
    ? options
        .map((val) =>
          val
            .split('_')
            .map((sub) => sub[0].toUpperCase())
            .join(''),
        )
        .join(',')
    : undefined;

const fieldToUrl = (key: string, value: any) => {
  // Filters are serialised into separate params
  if (key === 'filters') {
    return Object.entries(value)
      .filter(([name, value]) => {
        if (['__typename', 'location'].includes(name)) {
          return false;
        }

        if (Array.isArray(value)) {
          return !!value.length;
        }

        return !!value;
      })
      .map(([key, value]) => ({
        key: `f.${key}`,
        value: Array.isArray(value) ? value.join(',') : value,
      }));
  }

  // Rename `pinnedMasterIds` to `mrIds`
  if (key === 'pinnedMasterIds') {
    return {
      key: 'mrIds',
      value: value.length ? value.join(',') : undefined,
    };
  }

  // Stringify room configurations
  if (key === 'rooms') {
    return {
      key,
      value: stringifyRooms(value as RoomConfiguration[]),
    };
  }

  // Transfer cancellation policy to abbreviation (e.g. FREE_CANCELLATION -> FC)
  if (key === 'cancellationPolicy') {
    return {
      key,
      value: mapCancellationPolicies(value),
    };
  }

  // e.g. departureAirports, boardBasis
  if (Array.isArray(value)) {
    return {
      key,
      value: value.length ? value.join(',') : undefined,
    };
  }

  // Format dates to ISO format
  if (isDayjs(value)) {
    return {
      key,
      value: value.format(ISO_DATE_FORMAT),
    };
  }

  // Otherwise just return the value as is
  if (value !== null && typeof value !== 'undefined') {
    return { key, value };
  }

  return;
};

export const getUrlFromSearchSelection = (
  state: Partial<SearchSelection | { date: Dayjs | string | undefined }>,
  fields = Object.keys(state),
) =>
  new URLQueryParams(
    fields
      .filter((name) => name !== '__typename')
      .map((name: any) => fieldToUrl(name as string, (state as any)[name]))
      .reduce(
        (acc, current) => {
          if (!current) {
            return acc;
          }

          if (Array.isArray(current)) {
            current.forEach(({ key, value }) => {
              acc[key] = value as string;
            });

            return acc;
          }

          if (typeof current.value !== 'undefined') {
            acc[current.key] = current.value;
          }

          return acc;
        },
        {} as Record<string, string>,
      ),
  ).toString();
