import { sendClientEvent, sendEventsPromEvent } from './useClientEventTracking';
import { sendEvent } from '../sendEvent';
import { EcommerceProduct, ErrorEventType, TrackingEvent } from '../types';
import { getClientSiteCode, getPageTypeClient } from '@Contexts/contexts';

export const useProductPaymentTracking = () => async (data: EcommerceProduct) => {
  if (data.list === 'PaymentFailed') {
    const siteCode = getClientSiteCode();
    const pageType = await getPageTypeClient(window.location.pathname);
    sendClientEvent({
      type: 'failedPayment',
      subType: data.paymentInstrumentType,
      siteCode,
      pageType,
    });
    sendEventsPromEvent({
      siteCode: siteCode!,
      type: ErrorEventType.failedPayment,
      subType: data.paymentInstrumentType,
    });
  }

  sendEvent({
    event: TrackingEvent.productPayment,
    ...data,
  });
};
