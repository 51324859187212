import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppContext } from '@Contexts/contexts';
import { useExperimentTracking } from '@Core/tracking/hooks/useExperimentTracking';
import { useFeatureFlagStore } from '@Stores/FeatureFlag/FeatureFlagStore';

export const useFeatureFlag = <T = boolean>(name: string): T | false => {
  const { site, originalPageType } = useAppContext();
  const { location } = useHistory();
  // Cache the initial page details so that when asynchronously transitioning into a generic page
  // the existing feature flags don't see themselves as having moved to the new page until the page
  // transition completely rerenders them.
  const initialPage = useRef({ site, location });
  const flag = useFeatureFlagStore((state) =>
    state.flag({ name, ...initialPage.current, originalPageType }),
  );

  const trackExperiment = useExperimentTracking();

  // Send tracking once if FF was added by an experiment
  useEffect(() => {
    trackExperiment(name, flag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const value = flag?.value;

  if (value === true || value === 'true') {
    return true as unknown as T;
  }

  if (value === false || value === 'false' || value === undefined) {
    return false;
  }

  return value as unknown as T;
};
