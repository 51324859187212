export function isEmpty(value: any): boolean {
  if (!value) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  return typeof value === 'object' && Object.keys(value).length === 0;
}
