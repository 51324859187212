import React, { useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';

import { sendEvent } from '../sendEvent';
import {
  ImageGalleryTrackingObject,
  TrackingEvent,
  WebEventCategory,
  WebEventTrackingObject,
} from '@Core/tracking/types';

export enum DOMInteractionEvent {
  CLICK = 'click',
  KEYUP = 'keyup',
  SWIPE = 'swipe',
}

const InteractionEventToEventCategoryMap = {
  [DOMInteractionEvent.CLICK]: WebEventCategory.click,
  [DOMInteractionEvent.KEYUP]: WebEventCategory.keyboard,
  [DOMInteractionEvent.SWIPE]: WebEventCategory.swipe,
};

export type InteractionEvent =
  | React.KeyboardEvent
  | React.MouseEvent
  | KeyboardEvent
  | MouseEvent
  | Event;

type InteractionTrackingObject = {
  event: DOMInteractionEvent;
  action: string;
  label?: string;
  filter?: (e: InteractionEvent) => boolean;
  eventCallback?: (e: InteractionEvent) => void;
  deps?: Array<any>;
  useGlobalEvent?: boolean;
};

export const isKeyboardEvent = (e: any): e is KeyboardEvent => typeof e.key !== 'undefined';

export const trackEvent = (event: DOMInteractionEvent, action: string, label?: string) =>
  sendEvent<WebEventTrackingObject>({
    event: TrackingEvent.webEvent,
    category: InteractionEventToEventCategoryMap[event],
    action,
    label,
  });

export const trackImageGalleryEvent = ({
  event,
  label,
  masterId,
  position,
  name,
}: {
  event: DOMInteractionEvent;
  position: number;
  label?: string;
  masterId?: string | number;
  name?: string;
}) =>
  sendEvent<ImageGalleryTrackingObject>({
    event: TrackingEvent.webEvent,
    category: InteractionEventToEventCategoryMap[event],
    action: 'ImageGallery',
    label,
    masterId,
    name,
    position,
  });

export const trackEventClick = (action: string, label?: string) =>
  sendEvent({
    event: TrackingEvent.webEvent,
    category: InteractionEventToEventCategoryMap[DOMInteractionEvent.CLICK],
    action,
    label,
  } as WebEventTrackingObject);

export const useInteractionTracking = ({
  event,
  action,
  label,
  filter = () => true,
  eventCallback = () => {},
  deps = [],
  useGlobalEvent = false,
}: InteractionTrackingObject) => {
  const ref = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line react/no-find-dom-node
    const eventNode = useGlobalEvent ? window : findDOMNode(ref.current as HTMLElement | null);

    if (!eventNode || !InteractionEventToEventCategoryMap[event]) {
      return () => {};
    }

    const handleEvent = (e: InteractionEvent) => {
      if (filter(e)) {
        eventCallback(e);
        trackEvent(event, action, label);
      }
    };

    eventNode.addEventListener(event, handleEvent);

    return () => {
      eventNode.removeEventListener(event, handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, ref, ...deps]);

  return ref;
};
