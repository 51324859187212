import { v4 as uuidv4 } from 'uuid';

import { sendEvent } from './sendEvent';
import { SearchActionTrackingObject, TrackingEvent } from './types';
import { SearchAvailabilityStore } from '@Stores/SearchAvailabilityStore';
import { SearchResultsStore } from '@Stores/SearchResultsStore';
import { SearchSelectionStore } from '@Stores/SearchSelectionStore';
import { createSearchTrackingObject } from '@Stores/searchStoreTracker';

export const trackSearchChanges = (
  selection: SearchSelectionStore,
  availability: SearchAvailabilityStore,
  isInitial?: boolean,
  results?: SearchResultsStore,
) => {
  const newSearchId = uuidv4();

  const trackingObject: SearchActionTrackingObject = {
    event: TrackingEvent.searchChange,
    ...createSearchTrackingObject(selection, availability, results),
    isInitial,
    lastSearchId: availability.searchId,
    searchId: newSearchId,
  };

  sendEvent(trackingObject);
  availability.setSearchId(newSearchId);
};
