import { getGlobalThis } from '@Core/getGlobalThis';

function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

class IdleDeadlineShim implements IdleDeadline {
  initTime: number;

  constructor() {
    this.initTime = Date.now();
  }

  // eslint-disable-next-line class-methods-use-this
  get didTimeout(): boolean {
    return false;
  }

  timeRemaining() {
    return Math.max(0, 50 - (Date.now() - this.initTime));
  }
}

const timeoutRange = (timeout: number, range = 1000): [number, number] =>
  timeout > 2 * range ? [timeout - range, timeout] : [timeout, timeout + range];

const requestIdleCallbackShim: typeof globalThis.requestIdleCallback = (fn, opts) => {
  const deadline = new IdleDeadlineShim();

  const id: number = setTimeout(
    () => fn(deadline),
    randomIntFromInterval(...timeoutRange(opts?.timeout ?? 3000)),
  ) as any;

  return id;
};

export const requestIdleCallback =
  (getGlobalThis() as typeof globalThis).requestIdleCallback || requestIdleCallbackShim;

export const cancelIdleCallback = () =>
  (getGlobalThis() as typeof globalThis).cancelIdleCallback || clearTimeout;
