import { useEffect } from 'react';
import { useClient } from 'urql';

import syncFavouritesMutation from './graphql/syncFavourites.gql';
import { FavouritesList } from './interfaces';
import { isUnauthenticatedError } from './utils';
import { LocalStorageFavouritesList, Mutation, SiteCode } from '@AuroraTypes';
import { useAppContext } from '@Contexts/contexts';
import { LocalStorage } from '@Contexts/UserPreferencesContext/LocalStorage';
import { captureClientError } from '@Core/errors/errors';
import { useFavouritesStore } from '@Stores/StoreContext';

const transformMultiSiteLocalFavourites = (
  favouritesInLocalStorage: Partial<Record<SiteCode, unknown>>,
) => {
  const transformedLocalLists: LocalStorageFavouritesList[] = [];
  for (const [siteCode, listsInSiteCode] of Object.entries(favouritesInLocalStorage)) {
    const lists = (listsInSiteCode as FavouritesList[]).map(({ title, search, items }) => ({
      title,
      search,
      items,
      siteCode,
    }));

    transformedLocalLists.push(...lists);
  }

  return transformedLocalLists;
};

export const useFavouritesSync = () => {
  const appContext = useAppContext();
  const urqlClient = useClient();
  const createFavouritesLists = useFavouritesStore((state) => state.createFavouritesLists);

  const handleSyncFavourites = async () => {
    const localStorage = new LocalStorage(appContext);
    const favouritesInLocalStorage = localStorage.readAllSites('lvh_favourites');

    if (favouritesInLocalStorage) {
      const transformedLocalLists = transformMultiSiteLocalFavourites(favouritesInLocalStorage);

      const { data, error } = await urqlClient
        .mutation<Mutation>(syncFavouritesMutation, { input: transformedLocalLists })
        .toPromise();

      const syncFavouritesResponse = data?.User.syncFavouritesV2;
      if (isUnauthenticatedError(syncFavouritesResponse)) {
        // User not logged in, so just return
        return;
      }

      const syncedFavouritesLists = syncFavouritesResponse?.favouritesLists.items;
      if (error || !syncedFavouritesLists || syncedFavouritesLists.length < 1) {
        throw new Error('Failed to sync local storage favourites');
      }

      const mappedSyncedLists = syncedFavouritesLists.map((list) => ({
        id: list.id,
        title: list.name,
        search: list.searchQuery,
        items: list.masterIds,
      }));

      createFavouritesLists(mappedSyncedLists);

      window.localStorage.removeItem('lvh_favourites');
    }
  };

  useEffect(() => {
    // Sync local storage favourites to backend and then delete local storage favourites
    async function syncFavourites() {
      try {
        await handleSyncFavourites();
      } catch (e) {
        captureClientError('Failed to sync local storage favourites');
      }
    }

    syncFavourites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
