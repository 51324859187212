import React, { useContext, memo } from 'react';

import { Currency } from '@AuroraTypes';
import { currencyCodeToSymbol } from '@Constants/CurrencyCode';

export interface CurrencyFormat {
  (value: number): string;
}

const CurrencyContext = React.createContext<CurrencyFormat>(() => '');

export const useCurrency = () => useContext(CurrencyContext);

interface CurrencyFormatProps {
  locale: string;
  currency: Currency;
}

const fallbackCurrencyFormat =
  ({ currency }: { currency: Currency }) =>
  (value: number) => {
    const currencySymbol = currencyCodeToSymbol[currency];

    return `${currencySymbol}${value
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      .replace(/\.00$/, '')}`;
  };

const currencyFormat =
  ({ locale, currency }: CurrencyFormatProps) =>
  (value: number) => {
    // Mobile Chrome has a bug where GBP for en-GB is displayed as GBP rather than £
    const localePatched = locale === 'en-GB' ? 'en' : locale;
    const isDecimal = value % 1 !== 0;

    return Intl.NumberFormat(localePatched, {
      style: 'currency',
      minimumFractionDigits: isDecimal ? 2 : 0,
      maximumFractionDigits: 2,
      currency,
    }).format(value);
  };

const currencyFormatWithFallback =
  global.Intl && typeof global.Intl.NumberFormat === 'function'
    ? currencyFormat
    : fallbackCurrencyFormat;

export const CurrencyProvider: React.FC<CurrencyFormatProps> = memo(
  ({ locale, currency, children }) => (
    <CurrencyContext.Provider value={currencyFormatWithFallback({ locale, currency })}>
      {children}
    </CurrencyContext.Provider>
  ),
);
