import baseLoadable, { Options, DefaultComponent } from '@loadable/component';

import { requestIdleCallback } from './requestIdleCallback';

export interface PreLoadedLoadableOptions<T> extends Options<T> {
  timeout?: number;
}

/**
 * Extension on top of loadable to preload lazy components/chunks when the page is idle.
 * Used for components/chunks that are not required immediately on first load.
 */
export function preLoadedLoadable<T>(
  loadFn: (props: T) => Promise<DefaultComponent<T>>,
  options?: PreLoadedLoadableOptions<T>,
) {
  const { timeout = 3000, ...opts } = options || {};

  const Loadable = baseLoadable(loadFn, opts);

  if (typeof window !== 'undefined') {
    requestIdleCallback(() => Loadable.preload(), { timeout });
  }

  return Loadable;
}

export function preLoadedLoadableLib<T>(
  loadFn: (props: T) => Promise<DefaultComponent<T>>,
  options?: PreLoadedLoadableOptions<T>,
) {
  const { timeout = 3000, ...opts } = options || {};

  const Loadable = baseLoadable.lib(loadFn, opts);

  if (typeof window !== 'undefined') {
    requestIdleCallback(() => Loadable.preload(), { timeout });
  }

  return Loadable;
}
