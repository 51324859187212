/* eslint-disable no-param-reassign */
import { Exchange } from '@urql/core';
import { pipe, map } from 'wonka';

/**
 * This exchange removes the `__typename` property from the query variables and the returned data.
 * This is a faster alternative solution to URQL's maskTypeName:
 * https://github.com/FormidableLabs/urql/blob/main/packages/core/src/utils/maskTypename.ts
 */
export const removeTypeNames = (obj: any) =>
  JSON.parse(JSON.stringify(obj, (key, value) => (key === '__typename' ? undefined : value)));

export const removeTypeNamesExchange: Exchange =
  ({ forward }) =>
  (operations$) =>
    pipe(
      operations$,
      map((operation) => ({
        ...operation,
        ...(typeof operation.variables === 'object' && {
          variables: removeTypeNames(operation.variables),
        }),
      })),
      forward,
    );
