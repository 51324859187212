import { Image } from '@AuroraTypes';

export interface FavouritesList {
  id: string;
  updatedAt: number;
  title: string;
  search: string; // serialised SearchSelection
  items: number[]; // masterIds
}

export enum NotificationType {
  Added = 'added',
  Removed = 'removed',
}

export interface Notification {
  timerId: any;
  type: NotificationType;
  listInfo?: {
    id: string;
    title: string;
  };
  masterId: number;
  isOpened: boolean;
  previousLists?: FavouritesList[];
  hotelInfo?: HotelInfo;
  previousHotelRemoved?: {
    masterId: number;
    listId: string;
    title: string;
  };
}

export interface HotelInfo {
  name: string;
  image?: Image;
  starRating?: number;
  reviewCount?: number;
  reviewRating?: number;
}
