import { useCallback, useRef } from 'react';

import { sendEvent } from '../sendEvent';
import { FormErrorTracking, TrackingEvent } from '../types';

export interface FormErrorTrackingRef {
  [uniqId: string]: boolean;
}

/**
 * If we pass uniqId, then this hook will catch an error with this Id, just one time.
 */

export const useFormErrorTracking = () => {
  const memory = useRef<FormErrorTrackingRef>({});

  const formErrorTracking = useCallback(
    (data: FormErrorTracking, uniqId?: string) => {
      if (!uniqId) {
        sendEvent({
          event: TrackingEvent.formError,
          ...data,
        });

        return;
      }

      if (memory.current[uniqId]) {
        return;
      }

      sendEvent({
        event: TrackingEvent.formError,
        ...data,
      });

      memory.current[uniqId] = true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memory.current],
  );

  const clearFormErrorMemory = useCallback(
    (uniqId: string) => {
      if (memory.current[uniqId]) {
        delete memory.current[uniqId];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memory.current],
  );

  return { formErrorTracking, clearFormErrorMemory };
};
