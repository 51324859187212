import { Currency } from '@AuroraTypes';

export const currencyCodeToSymbol: Record<Currency, string> = {
  GBP: '£',
  EUR: '€',
  USD: '$',
  SEK: 'kr',
  DKK: 'Kr.',
  NOK: 'kr',
  AUD: '$',
  NZD: '$',
};
