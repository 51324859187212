import { sendEvent } from '@Core/tracking/sendEvent';
import { TrackingEvent, WebEventCategory, WebEventTrackingObject } from '@Core/tracking/types';

export const creatorBaseTracking =
  <T extends string>(category: WebEventCategory) =>
  (action: string) =>
  (label: T) => {
    sendEvent<WebEventTrackingObject>({
      event: TrackingEvent.webEvent,
      category,
      action,
      label,
    });
  };
