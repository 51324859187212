import { sendErrorWhenLoaded } from './sentryLoader';
import { CaptureError } from './types';
import { ClientError } from '@Core/errors/client/ClientError';
import { OperationErrorResult } from '@Exchanges/tapError';

export const captureClientError: CaptureError = (error, extra, tags) => {
  if (typeof window === 'undefined' || process.env.NODE_ENV === 'test') {
    // Using require here so this won't get added to the client bundle
    // eslint-disable-next-line global-require
    require('@Server/handlers/handle-error/sentry').captureServerError(null, error);

    return;
  }

  sendErrorWhenLoaded(error, extra, tags);
};

export function sendUrqlError(res: OperationErrorResult) {
  const { error, operation } = res;
  const { graphQLErrors, networkError } = error;

  captureClientError(error, {
    operation,
    graphQLErrors,
    networkError,
    error,
    res,
  });
}

export function isClientError(e: unknown): e is ClientError {
  return e instanceof ClientError;
}
