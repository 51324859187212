import { EcommerceProduct } from './types';
import { convertPenceToPounds } from '@Core/prices/magnitudes';

export const transformAddToCart = (product: EcommerceProduct) => ({
  category: product.category,
  id: product.masterId,
  price: convertPenceToPounds(product.price as number),
  name: product.hotelName,
  quantity: 1,
});
