import { sendEvent } from '../sendEvent';
import { PageViewTrackingObject, TrackingEvent } from '../types';
import { useAppContext } from '@Client/context/contexts';
import { useUserPreferences } from '@Client/context/UserPreferencesContext/UserPreferencesContext';
import { useRouteContext } from '@Contexts/RouteContext';
import { detectConnection } from '@Core/detectConnection';

interface PageViewTracking {
  status?: '500' | '404';
}

export const usePageViewTracking = () => {
  const { site, browserType } = useAppContext();
  const { lvhId, criteoId } = useUserPreferences();
  const matchedRoute = useRouteContext();

  return ({ status }: PageViewTracking = {}) => {
    if (typeof window === 'undefined') {
      return;
    }
    const { pathname, href, search } = window.location;
    const { referrer } = window.document;

    const trackingObject: PageViewTrackingObject = {
      event: TrackingEvent.pageView,
      countryCode: site.countryCode,
      criteoid: criteoId.get(),
      lvhId: lvhId.get(),
      browserType,
      designSystem: matchedRoute?.designSystem,
      url: href,
      path: pathname,
      search,
      referrer,
      status,
      connection: detectConnection(),
    };

    sendEvent(trackingObject);
  };
};
