/* eslint-disable class-methods-use-this */
import { SessionStorageUserPreferencesConfiguration, UserPreferenceStorage } from './interfaces';

export class SessionStorage implements UserPreferenceStorage<any> {
  read({ sessionStorageKey }: SessionStorageUserPreferencesConfiguration<any>): any {
    if (typeof window === 'undefined') {
      throw new Error('SessionStorage access does not work on SSR');
    } else if (!sessionStorageKey) {
      throw new Error(`Invalid SessionStorage key: "${sessionStorageKey}"`);
    }

    return this.readRaw(sessionStorageKey);
  }

  write(
    { sessionStorageKey }: SessionStorageUserPreferencesConfiguration<any>,
    value: string,
    forceUpdate: boolean = false,
  ) {
    if (typeof window === 'undefined') {
      throw new Error('SessionStorage access does not work on SSR');
    } else if (!sessionStorageKey) {
      throw new Error(`Invalid SessionStorage key: "${sessionStorageKey}"`);
    }

    try {
      let currentValue = this.readRaw(sessionStorageKey);
      if (typeof currentValue !== 'string') {
        currentValue = JSON.stringify(currentValue);
      }

      // Do not update if the current value is the same
      if (!forceUpdate && currentValue === value) {
        return;
      }

      window.sessionStorage.setItem(sessionStorageKey, value);
    } catch {
      // Do nothing when can't save to SessionStorage
    }
  }

  remove({ sessionStorageKey }: SessionStorageUserPreferencesConfiguration<any>) {
    if (typeof window === 'undefined') {
      throw new Error('SessionStorage access does not work on SSR');
    } else if (!sessionStorageKey) {
      throw new Error(`Invalid SessionStorage key: "${sessionStorageKey}"`);
    }

    window.sessionStorage.removeItem(sessionStorageKey);
  }

  private readRaw(sessionStorageKey: string) {
    try {
      return window.sessionStorage.getItem(sessionStorageKey) ?? undefined;
    } catch {
      // Returning undefined, so we can fall back to use the default value provided
      return;
    }
  }
}
