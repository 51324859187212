import { SearchAvailabilityStore } from './SearchAvailabilityStore';
import { SearchResultsStore } from './SearchResultsStore';
import { SearchSelectionStore } from './SearchSelectionStore';
import {
  CancellationPolicies,
  DynamicPackageOrderedFilter,
  DynamicPackageResult,
  RoomConfiguration,
} from '@AuroraTypes';
import { cleanObject, flattenObjectValues } from '@Core/helpers/data';
import { ISO_DATE_FORMAT } from '@Dates/dates';

type ChildGroup = 'infants' | 'children' | 'teenagers';

export const getChildGroup = (childAge: number): ChildGroup => {
  if (childAge <= 1) {
    return 'infants';
  }
  if (childAge > 1 && childAge <= 11) {
    return 'children';
  }

  return 'teenagers';
};

const groupByChildGroup = (children: number[]) =>
  children.reduce(
    (acc, childAge) => {
      const childGroup = getChildGroup(childAge);

      return {
        ...acc,
        ...(childGroup ? { [childGroup]: acc[childGroup] ? acc[childGroup] + 1 : 1 } : {}),
      };
    },
    {} as Record<ChildGroup, number>,
  );

export const breakDownChildren = (children: number[]) => {
  const groupedChildren = groupByChildGroup(children);

  return Object.keys(groupedChildren)
    .map((group) => `${groupedChildren[group as ChildGroup]} ${group}`)
    .join(',');
};

export const normaliseRooms = (rooms: RoomConfiguration[]) => ({
  total_rooms: rooms.length,
  ...rooms.reduce(
    (acc, room, index) => ({
      ...acc,
      [`room${index}`]: [
        room.adults ? `${room.adults} adults` : '',
        room.childAges ? breakDownChildren(room.childAges) : '',
      ]
        .filter((value) => !!value)
        .join(','),
    }),
    {},
  ),
});

export const getAdultsAndChildren = (rooms: RoomConfiguration[]) =>
  rooms.reduce(
    (acc, { adults, childAges = [] }) => ({
      total_adults: acc.total_adults + adults,
      total_children: acc.total_children + childAges.length,
    }),
    {
      total_adults: 0,
      total_children: 0,
    },
  );

export const getHasRefundableOffers = (
  searchFilters?: DynamicPackageOrderedFilter[],
  cancellationPolicies?: CancellationPolicies,
): boolean | undefined => {
  let hasRefundableOffers;
  if (searchFilters) {
    const cancellationSearchFilter = searchFilters.find(({ id }) => id === 'cancellationPolicies');
    hasRefundableOffers = cancellationSearchFilter
      ? cancellationSearchFilter.options[0]?.count !== 0
      : undefined;
  } else if (cancellationPolicies) {
    hasRefundableOffers = cancellationPolicies.options.some(({ disabled }) => !disabled);
  }

  return hasRefundableOffers;
};

export const getCoreSearchCriteria = (
  selection: SearchSelectionStore,
  availability: SearchAvailabilityStore,
) => ({
  departureAirports: availability.departureAirports
    .filter(({ airport }) => selection.departureAirports.includes(airport.id))
    .map(({ airport }) => airport.name),
  nights: selection.nights,
  ...(selection.date && {
    date: selection.date.format(ISO_DATE_FORMAT),
    returnDate: selection.date.add(selection.nights, 'days').format(ISO_DATE_FORMAT),
  }),
  rooms: normaliseRooms(selection.rooms),
  ...getAdultsAndChildren(selection.rooms),
});

export const createSearchTrackingObject = (
  selection: SearchSelectionStore,
  availability: SearchAvailabilityStore,
  results?: SearchResultsStore,
) =>
  flattenObjectValues(
    cleanObject({
      ...selection,
      ...getCoreSearchCriteria(selection, availability),
      destinations: availability.destinations
        .filter(({ id }) => selection.destinationIds.includes(id))
        .map(({ name }) => name),
      totalResults: results?.totalResults,
      topMasterIds: (results?.results || [])
        .filter((result) => !!(result as DynamicPackageResult).hotel)
        .slice(0, 5)
        .map((result) => (result as DynamicPackageResult).hotel.accommodation.id),
      pinnedMasterIds: (results?.pinnedResults || []).map((result) => result.hotel.id),
      hasRefundableOffers: getHasRefundableOffers(
        results?.orderedFilters,
        availability.cancellationPolicies,
      ),
      viewMode: selection.viewMode,
    }),
  );
