import { CaptureError } from './types';

type OnErrorData = {
  e: Parameters<OnErrorEventHandlerNonNull>;
};

type OnPromiseRejectionData = {
  p: PromiseRejectionEvent;
};

type CaptureErrorData = {
  c: Parameters<CaptureError>;
};

type QueueData = OnErrorData | OnPromiseRejectionData | CaptureErrorData;

const MAX_QUEUE_SIZE = 100;
let queueData: QueueData[] = [];

let oldOnerror: OnErrorEventHandler;
let oldOnunhandledrejection: WindowEventHandlers['onunhandledrejection'];

let hasSentryLoaded = false;
let hasInit = false;

let sentrySendError: CaptureError;

const fallbackSendError: CaptureError = (error, extra, tags) => {
  // eslint-disable-next-line no-console
  console.error(error, extra, tags);
};

function queue(data: QueueData) {
  if (queueData.length > MAX_QUEUE_SIZE) {
    queueData.shift();
  }

  queueData.push(data);
}

export async function loadSentry(): Promise<{ sendError: CaptureError }> {
  // Do not load Sentry when the page is served from local file system
  if (window.location.protocol === 'file:') {
    return { sendError: fallbackSendError };
  }

  try {
    const { initSentry, sendError } = await import(/* webpackChunkName: "sentry" */ './sentry');

    if (hasSentryLoaded) {
      return {
        sendError,
      };
    }

    sentrySendError = sendError;
    hasSentryLoaded = true;

    // Restore onerror/onunhandledrejection handlers
    window.onerror = oldOnerror;
    window.onunhandledrejection = oldOnunhandledrejection;

    initSentry();

    // Because we installed the SDK, at this point we have an access to TraceKit's handler,
    // which can take care of browser differences (eg. missing exception argument in onerror)
    const tracekitErrorHandler = window.onerror;
    const tracekitUnhandledRejectionHandler = window.onunhandledrejection;

    // And now capture all previously caught exceptions
    [...queueData].forEach((data) => {
      if ('e' in data && tracekitErrorHandler) {
        tracekitErrorHandler.apply(window, data.e);
      } else if ('p' in data && tracekitUnhandledRejectionHandler) {
        tracekitUnhandledRejectionHandler.apply(window, [data.p]);
      } else if ('c' in data) {
        sentrySendError(...data.c);
      }
    });

    queueData = [];

    return {
      sendError: sentrySendError,
    };
  } catch (err) {
    // Restore onerror/onunhandledrejection handlers
    window.onerror = oldOnerror;
    window.onunhandledrejection = oldOnunhandledrejection;

    sentrySendError = fallbackSendError;

    // eslint-disable-next-line no-console
    console.error(err);
  }

  return { sendError: fallbackSendError };
}

export const sendErrorWhenLoaded: CaptureError = (error, extra, tags) => {
  if (sentrySendError) {
    sentrySendError(error, extra, tags);

    return;
  }

  queue({ c: [error, extra, tags] });

  // force load sentry on error
  loadSentry();
};

export function initGlobalErrorHandlers() {
  if (hasInit) {
    return;
  }
  hasInit = true;

  oldOnerror = window.onerror;
  window.onerror = async function onError(...args) {
    queue({
      e: args,
    });

    if (oldOnerror) {
      oldOnerror.apply(window, args);
    }

    // force load sentry on error
    loadSentry();
  };

  oldOnunhandledrejection = window.onunhandledrejection;
  window.onunhandledrejection = async function onUnhandledrejection(event: PromiseRejectionEvent) {
    queue({
      p: event,
    });

    if (oldOnunhandledrejection) {
      oldOnunhandledrejection.apply(window, [event]);
    }

    // force load sentry on error
    loadSentry();
  };
}
