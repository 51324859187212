import { sendEvent } from '../sendEvent';
import { EcommerceProduct, TrackingEvent } from '../types';

type Event = TrackingEvent.couponApplied | TrackingEvent.couponEntered | TrackingEvent.couponDenied;

export const useVoucherTracking = () => (event: Event, data: EcommerceProduct) => {
  sendEvent({
    ...data,
    event,
  });
};

export const useVoucherEnteredTracking = () => (data: EcommerceProduct) => {
  sendEvent({
    event: TrackingEvent.couponEntered,
    ...data,
  });
};

export const useVoucherAppliedTracking = () => (data: EcommerceProduct) => {
  sendEvent({
    event: TrackingEvent.couponApplied,
    ...data,
  });
};

export const useVoucherDeniedTracking = () => (data: EcommerceProduct) => {
  sendEvent({
    event: TrackingEvent.couponDenied,
    ...data,
  });
};
